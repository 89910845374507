import React, { useContext } from "react";
import "./RevisionCourses.css";
import ImageByContentCard from "../../Components/ImageByContentCard";
import ContentByImageHeroSection from "../../Components/ContentByImageHeroSection";
import HeaderCard from "../../Components/headerCard";
import UnborderedList from "../../Components/UnOrderedList";
import groupPeople from "./../../assets/Revision.png";
import InvisibleCard from "../../Components/invisibleCard";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import RevisionImage from "../../assets/reviseweb.png";
import RevisionImageMobile from "../../assets/revisewebMobile.png";
import mainImage from "../../assets/revisionCoursesImage.png";
import HomeSteps from "../../Components/homeSteps";

import MonthComponent from "./MonthComponent";
import RevisionCoursesDetails from "./RevisionCoursesDetails";
import secondImage from "../../assets/revisionCoursesSecond.jpg";
import AcademicTimeline from "./AcademicTimeline";
import First from "../../assets/1st.png";
import Second from "../../assets/2nd.png";
import Third from "../../assets/3rd.png";
import { MyContext } from "../../Context/MyContext";
import { Helmet } from "react-helmet";
import { Button } from "@mui/material";

export default function RevisionCourses() {
  const navigate = useNavigate();

  const { seoData } = useContext(MyContext);
  const [thisPageSEO, setThisPageSEO] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (seoData) {
      setThisPageSEO(seoData?.find((e) => e?.pageName === "Revision Courses"));
    }
  }, [seoData]);

  const invisibleCardInfo = {
    title: "Your Journey to Success - Just 3 Steps Away.",
    cardsInfo: [
      {
        icons: "fa-regular fa-comments cardIcon",
        title: "Select Your Tailored Package",
        para: "At IB Innovators, we offer flexible tutoring packages designed to cater to your exact requirements.",
      },
      {
        icons: "fa-regular fa-thumbs-up cardIcon",
        title: "Your Perfect Tutor Match",
        para: "Drawing from our extensive network of tutors, we meticulously match you with the tutor best suited to your needs.",
      },
      {
        icons: "fa-solid fa-chalkboard-user cardIcon",
        title: "Meet Your Tutor - Ignite the Journey",
        para: "Once your perfect tutor match is found, you'll connect with them through an online video call, allowing you to interact and embark on your learning journey.",
      },
    ],
  };

  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 600); // You can adjust the width threshold as needed
  };

  // Listen for window resize events to update isMobile state
  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const popularRef = useRef();
  const upcommingRef = useRef();
  const requestRef = useRef();

  const summerRef = useRef();
  const rapidRef = useRef();
  const winterRef = useRef();
  const springRef = useRef();

  const createHelmetTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const headTags = Array.from(doc.head.children);

    return headTags.map((tag, index) => {
      const tagName = tag.tagName.toLowerCase();

      if (tagName === "title") {
        return <title key={index}>{tag.innerText}</title>;
      } else if (tagName === "meta") {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <meta key={index} {...attributes} />;
      } else if (tagName === "link") {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <link key={index} {...attributes} />;
      } else if (tagName === "script" && tag.type === "application/ld+json") {
        return (
          <script key={index} type="application/ld+json">
            {tag.innerText}
          </script>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Helmet>
        {thisPageSEO?.schema && createHelmetTags(thisPageSEO.schema)}
      </Helmet>

      <div
        style={{
          flex: 1,
          width: "100%",
          background: "red",
          height: imageLoaded ? "max-content" : "600px",
          background: "#14549f",
        }}
      >
        <div style={{ maxWidth: "1800px", flex: 1, margin: "0px auto" }}>
          {isMobile ? (
            <img
              src={RevisionImageMobile}
              width="100%"
              alt=""
              onLoad={() => setImageLoaded(true)}
              height="auto"
              style={{ maxWidth: "1800px" }}
            />
          ) : (
            <img
              src={RevisionImage}
              onLoad={() => setImageLoaded(true)}
              width="100%"
              alt=""
              height="auto"
            />
          )}
        </div>
      </div>

      <div
        style={{
          maxWidth: "1600px",
          margin: "0 auto",
        }}
      >
        {/* TOP TWO PARAGRAPHS */}
        {/* 
        <div className="mt-5 mb-5" style={{marginRight: '5vw', marginLeft: '5vw'}}>
          <div className="content-container">
            <div style={{ flex: 1.5, marginRight: "1rem" }}>
              <div
                className="homePageHeadings mt-5 mb-5 text-center"
                style={{
                  fontSize: "2.5rem",
                  color: "rgb(14, 56, 136)",
                  fontWeight: "bold",
                }}
              >
                IBDP Final Exam Revision Courses
              </div>
              <div
                style={{ marginBottom: 0, fontSize: "1.5rem" }}
                className="p-3"
              >
                <img
                  src={mainImage}
                  style={{
                    float: "right",
                    width: isMobile ? "100%" : "50%",
                    margin: "1rem",
                  }}
                  alt="Image"
                />

                <p>
                  We are here to help you feel confident, calm and focused
                  during your upcoming exam by preparing for your exam with IB
                  Diploma examiners who play a critical role in evaluating the
                  assessments of students pursuing an IB Diploma, assessing
                  written assignments, setting and marking final exams, and
                  attending training sessions to stay up-to-date with assessment
                  methodologies. Their work contributes to determining the
                  academic achievements of students and developing future
                  leaders and thinkers.{" "}
                </p>
                <p>
                  Our comprehensive and focused IB Diploma course includes both
                  study time and coverage of exam specifics. we will ensure the
                  student is fully prepared for test day as we provide you with:{" "}
                </p>

                <p>
                  Past papers, to help you prepare for your upcoming exam <br />
                  Tips on managing exam stress
                  <br />
                  Ways to pick up 'easy' marks in your final exams
                  <br />
                </p>

                <p>
                  No matter what subject or area you need help with, our tutors
                  are ready to help you today. If you don’t find the subject you
                  are looking for from the below list, then please let us know
                  via the chat feature to arrange a course for you and your
                  friends
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div
          className="mt-5 mb-5"
          style={{ marginRight: "5vw", marginLeft: "5vw" }}
        >
          <div className="content-container">
            <div style={{ flex: 1.5, marginRight: "1rem" }}>
              <div
                className="homePageHeadings mt-5 mb-5 text-center"
                style={{
                  fontSize: isMobile ? "2rem" : "2.5rem",
                  color: "rgb(14, 56, 136)",
                  fontWeight: "bold",
                }}
              >
                Revision Courses at{" "}
                <b>
                  IB{" "}
                  <span style={{ color: "rgb(156, 16, 16)" }}>INNOVATORS</span>
                </b>
              </div>
              <div
                style={{ marginBottom: 0, fontSize: "1.5rem" }}
                className="p-3"
              >
                <img
                  src={secondImage}
                  style={{
                    float: "right",
                    width: isMobile ? "100%" : "50%",
                    margin: "1rem",
                  }}
                  alt="Image"
                />

                <p>
                  At{" "}
                  <span style={{ color: "rgb(14, 56, 136)" }}>
                    <b>
                      IB{" "}
                      <span style={{ color: "rgb(156, 16, 16)" }}>
                        INNOVATORS
                      </span>
                    </b>
                  </span>
                  , we redefine academic excellence by offering personalized
                  guidance from IBDP examiners, ensuring a comprehensive
                  understanding of assessments and the latest methodologies. We
                  enable students to excel in their final exams by providing:
                </p>
                <ul>
                  <li>Tailored Courses</li>
                  <li>Focused Study Sessions</li>
                  <li>Past Papers Practice</li>
                  <li>Strategic Exam Tips</li>
                </ul>
                <p style={{ fontWeight: "bold", lineHeight: "40px" }}>
                  With us you can explore our{" "}
                  <span
                    onClick={() => {
                      popularRef.current.scrollIntoView({ behavior: "smooth" });
                    }}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Popular Courses
                  </span>{" "}
                  or enrol in our{" "}
                  <span
                    onClick={() => {
                      upcommingRef.current.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Upcoming Courses
                  </span>{" "}
                  or{" "}
                  <Button
                    style={{
                      backgroundColor: "rgb(156, 16, 16)",
                      color: "white",
                      padding: "5px 15px",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                      fontSize: '1.2rem'
                    }}
                    variant="contained"
                    onClick={() => {
                      requestRef.current.scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    Request Your Own Course
                  </Button>{" "}
                  for your ease.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* ACADEMIC TIMELINE */}
        <div style={{ marginRight: "5vw", marginLeft: "5vw" }}>
          <div
            className="homePageHeadings mt-5 mb-5 text-center"
            style={{
              fontSize: isMobile ? "2rem" : "2.5rem",
              color: "rgb(14, 56, 136)",
              fontWeight: "bold",
            }}
          >
            Our Academic Timeline
          </div>
          <AcademicTimeline
            summerRef={summerRef}
            rapidRef={rapidRef}
            winterRef={winterRef}
            springRef={springRef}
          />
        </div>

        {/* POPULAR COURSES  */}
        <div style={{ padding: "2rem" }}>
          <div>
            <div
              className="homePageHeadings text-center"
              style={{
                fontSize: isMobile ? "2rem" : "2.5rem",
                color: "rgb(156, 16, 16)",
                fontWeight: "bold",
                marginTop: "5rem",
                marginBottom: "1rem",
              }}
              ref={popularRef}
            >
              Popular Courses Category
            </div>
            <MonthComponent
              summerRef={summerRef}
              rapidRef={rapidRef}
              winterRef={winterRef}
              springRef={springRef}
            />
          </div>
        </div>

        {/* UPCOMMING AND REQUEST YOUR COURSE */}
        <div style={{ padding: "2rem", paddingBottom: 0 }}>
          <div>
            <RevisionCoursesDetails
              upcommingRef={upcommingRef}
              requestRef={requestRef}
            />
          </div>
        </div>

        {/* HOW COURSES WORK AT IBI */}
        <div style={{ padding: "2rem", paddingTop: "0rem" }}>
          <div>
            <div
              id="howItWork"
              style={{
                width: "100%",
                marginBottom: 0,
              }}
            >
              {invisibleCardInfo.title && (
                <div className="homePageHeadings text-center textTheme">
                  <div
                    style={{
                      fontSize: isMobile ? "2rem" : "2.5rem",
                      color: "rgb(14, 56, 136)",
                      fontWeight: "bold",
                    }}
                  >
                    How Courses Work at IBI?
                  </div>
                  <div
                    style={{
                      fontSize: isMobile ? "1.5rem" : "2rem",
                      color: "rgb(14, 56, 136)",
                      marginTop: "1.5rem",
                      fontWeight: "normal",
                    }}
                  >
                    Unlock your courses: Register a Request, Finalize your
                    Course, Boost your Preparation
                  </div>
                </div>
              )}

              {invisibleCardInfo.description && (
                <div style={{ textAlign: "center" }} className="p-3">
                  {invisibleCardInfo.description}
                </div>
              )}

              <div
                style={{
                  paddingLeft: "5vw",
                  paddingRight: "5vw",
                  color: "rgb(17, 44, 95)",
                }}
                className="row mt-5"
              >
                <React.Fragment>
                  <div
                    style={{ marginBottom: "1rem" }}
                    className={
                      "col-md-" + 12 / invisibleCardInfo.cardsInfo.length
                    }
                  >
                    <div
                      style={{
                        height: "100%",
                        justifyContent: "space-between",
                        flex: 1,
                        display: "flex",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{ textAlign: "center" }}
                      >
                        <div style={{ margin: "10px", flex: 1 }}>
                          <img
                            src={First}
                            alt="First Step"
                            style={{ width: "70%" }}
                          />
                        </div>
                        <h5
                          style={{ fontSize: "1.5rem", flex: 1 }}
                          className="card-title mb-0"
                        >
                          <b>Register a Course Request</b>
                        </h5>
                        <div
                          style={{
                            justifyContent: "space-between",
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "0px",
                              fontSize: "1.2rem",
                            }}
                            className="p-3"
                          >
                            Sign up for the course by submitting your details
                            and expressing your interests.
                          </div>

                          <div>
                            {/* <button
                  style={{
                    background: "transparent",
                    border: "1px solid rgb(17, 44, 95)",
                    color: "rgb(17, 44, 95)",
                    marginBottom: '1rem'
                  }}
                  onClick={() => navigate("/login")}
                >
                  Register Now
                </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>

                <React.Fragment>
                  <div
                    style={{ marginBottom: "1rem" }}
                    className={
                      "col-md-" + 12 / invisibleCardInfo.cardsInfo.length
                    }
                  >
                    <div
                      style={{
                        height: "100%",
                        justifyContent: "space-between",
                        flex: 1,
                        display: "flex",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{ textAlign: "center" }}
                      >
                        <div style={{ margin: "10px", flex: 1 }}>
                          <img
                            src={Second}
                            alt="First Step"
                            style={{ width: "70%" }}
                          />
                        </div>
                        <h5
                          style={{ fontSize: "1.5rem", flex: 1 }}
                          className="card-title mb-0"
                        >
                          <b>Finalize Your Course</b>
                        </h5>
                        <div
                          style={{
                            justifyContent: "space-between",
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "0px",
                              fontSize: "1.2rem",
                            }}
                            className="p-3"
                          >
                            Choose and confirm the specific course you want to
                            take, ensuring it aligns with your goals and
                            preferences.
                          </div>
                          <div>
                            {/* <button
                  style={{
                    background: "transparent",
                    border: "1px solid rgb(17, 44, 95)",
                    color: "rgb(17, 44, 95)",
                    marginBottom: '1rem'
                  }}
                  onClick={() => navigate("/howitworks")}
                >
                  Learn More...
                </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>

                <React.Fragment>
                  <div
                    style={{ marginBottom: "1rem" }}
                    className={
                      "col-md-" + 12 / invisibleCardInfo.cardsInfo.length
                    }
                  >
                    <div
                      style={{
                        height: "100%",
                        justifyContent: "space-between",
                        flex: 1,
                        display: "flex",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{ textAlign: "center" }}
                      >
                        <div style={{ margin: "10px", flex: 1 }}>
                          <img
                            src={Third}
                            alt="First Step"
                            style={{ width: "70%" }}
                          />
                        </div>
                        <h2
                          style={{ fontSize: "1.5rem", flex: 1 }}
                          className="card-title mb-0"
                        >
                          <b>Boost Your Preparation</b>
                        </h2>
                        <div
                          style={{
                            justifyContent: "space-between",
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "0px",
                              fontSize: "1.2rem",
                            }}
                            className="p-3"
                          >
                            Submerge yourself in the course material, actively
                            participate, and sharpen your skills for success
                            through focused learning.
                          </div>

                          <div>
                            {/* <button
                  style={{
                    background: "transparent",
                    border: "1px solid rgb(17, 44, 95)",
                    color: "rgb(17, 44, 95)",
                    marginBottom: '1rem'
                  }}
                  onClick={() => navigate("/howitworks")}
                >
                  Learn More...
                </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
