import React, { useState, useContext, useEffect } from 'react';
import { MyContext } from '../../../Context/MyContext';

export default function QueryFormThirdStep(props) {
    const { studentForm, setStudentForm } = useContext(MyContext);
    const { selectedCallBack, selectedProgram } = props;
    
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [numberOfIBSubjects, setNumberOfIBSubjects] = useState([]);
    const [numberOfOtherSubjects, setNumberOfOtherSubjects] = useState([]);

    useEffect(() => {console.log(selectedSubjects)}, [selectedSubjects])
    
  
    const handleChange = (index, newValue) => {
        const newSelectedSubjects = [...selectedSubjects];
        newSelectedSubjects[index] = newValue;
        setSelectedSubjects(newSelectedSubjects);
      }
      

  // Function to add more IB subjects
  const AddIBSubjects = () => {
    setNumberOfIBSubjects([...numberOfIBSubjects, IBSubjectsDropDown(numberOfIBSubjects.length)]);
  };

  const removeIBSubject = (index) => {
    // Remove from the visual components array
    const newNumberOfIBSubjects = [...numberOfIBSubjects];
    newNumberOfIBSubjects.splice(index, 1);
    setNumberOfIBSubjects(newNumberOfIBSubjects);
  
    // Remove from the selected subjects array
    const newSelectedSubjects = [...selectedSubjects];
    newSelectedSubjects.splice(index, 1);
    setSelectedSubjects(newSelectedSubjects);
  };
  

  

  // Function to add more Other subjects
  const AddOtherSubjects = () => {
    setNumberOfOtherSubjects([...numberOfOtherSubjects, OtherSubjectsDropDown(numberOfOtherSubjects.length)]);
  };

  // Function to remove Other subjects
  const removeOtherSubject = (index) => {
    // Remove from the visual components array
    const newNumberOfOtherSubjects = [...numberOfOtherSubjects];
    newNumberOfOtherSubjects.splice(index, 1);
    setNumberOfOtherSubjects(newNumberOfOtherSubjects);
  
    // Remove from the selected subjects array
    const newSelectedSubjects = [...selectedSubjects];
    newSelectedSubjects.splice(index, 1);
    setSelectedSubjects(newSelectedSubjects);
  };

  // JSX for IB Subjects dropdown
  const IBSubjectsDropDown = (index) => (
    <React.Fragment>
      <select onChange={(e) => handleChange(index, e.target.value)} className="form-select form-select-sm" aria-label=".form-select-sm example">
      <option value="">
                    Select
                </option>
                <option value="Arabic A: Language &amp; Literature">
                    Arabic A: Language &amp; Literature
                </option>
                <option value="Arabic A: Literature">
                    Arabic A: Literature
                </option>
                <option value="Arabic B">
                    Arabic B
                </option>
                <option value="Art History"
                >Art History
                </option>
                <option value="Biology">
                    Biology
                </option>
                <option value="Business Management (BM)">
                    Business Management (BM)
                </option>
                <option value="Chemistry">
                    Chemistry
                </option>
                <option value="Chinese A: Language">
                    Chinese A: Language
                </option>
                <option value="Chinese B">
                    Chinese B
                </option>
                <option value="Computer Science">
                    Computer Science
                </option>
                <option value="Danish A: Language &amp; Literature"
                >Danish A: Language &amp; Literature
                </option>
                <option value="Danish A: Literature">
                    Danish A: Literature
                </option>
                <option value="Danish B"
                >Danish B
                </option>
                <option value="Design Technology">
                    Design Technology
                </option>
                <option value="Economics">
                    Economics
                </option>
                <option value="English A: Language &amp; Literature">
                    English A: Language &amp; Literature
                </option>
                <option value="English A: Literature">
                    English A: Literature
                </option>
                <option value="English B">
                    English B
                </option>
                <option value="English: Literature &amp; Performance">
                    English: Literature &amp; Performance
                </option>
                <option value="Environmental Systems &amp; Societies (ESS)">
                    Environmental Systems &amp; Societies (ESS)
                </option>
                <option value="Extended Essay">Extended Essay
                </option>
                <option value="Film">
                    Film
                </option>
                <option value="French A: Language">
                    French A: Language
                </option>
                <option value="French A: Language &amp; Literature"
                >French A: Language &amp; Literature
                </option>
                <option value="French B">
                    French B
                </option>
                <option value="Geography">
                    Geography
                </option>
                <option value="German A: Language &amp; Literature"
                >German A: Language &amp; Literature
                </option>
                <option value="German A: Literature">
                    German A: Literature
                </option>
                <option value="German B">
                    German B
                </option>
                <option value="Global Politics">
                    Global Politics
                </option>
                <option value="Global Societies">
                    Global Societies
                </option>
                <option value="Greek (Classical) B">
                    Greek (Classical) B
                </option>
                <option value="History">
                    History
                </option>
                <option value="Information Technology in a Global Society (ITGS)"
                >Information Technology in a Global Society (ITGS)
                </option>
                <option value="Internal Assessment">Internal Assessment
                </option>
                <option value="Italian A: Language &amp; Literature"
                >Italian A: Language &amp; Literature
                </option>
                <option value="Italian A: Literature">
                    Italian A: Literature
                </option>
                <option value="Italian B">
                    Italian B
                </option>
                <option value="Latin B">
                    Latin B
                </option>
                <option value="Maths: Analysis &amp; Approaches (A&amp;A)"
                >Maths: Analysis &amp; Approaches (A&amp;A)
                </option>
                <option value="Maths: Applications &amp; Interpretation (A&amp;I)"
                >Maths: Applications &amp; Interpretation (A&amp;I)
                </option>
                <option value="Philosophy">
                    Philosophy
                </option>
                <option value="Physics">
                    Physics
                </option>
                <option value="Polish A: Language &amp; Literature"
                >Polish A: Language &amp; Literature
                </option>
                <option value="Polish A: Literature"
                >Polish A: Literature
                </option>
                <option value="Polish B"
                >Polish B
                </option>
                <option value="Portuguese A: Language &amp; Literature"
                >Portuguese A: Language &amp; Literature
                </option>
                <option value="Portuguese A: Literature"
                >Portuguese A: Literature
                </option>
                <option value="Portuguese B"
                >Portuguese B
                </option>
                <option value="Social &amp; Cultural Anthropology">
                    Social &amp; Cultural Anthropology
                </option>
                <option value="Spanish A: Language &amp; Literature"
                >Spanish A: Language &amp; Literature
                </option>
                <option value="Spanish A: Literature">
                    Spanish A: Literature
                </option>
                <option value="Spanish B">
                    Spanish B
                </option>
                <option value="Sports, Exercise &amp; Health Science">
                    Sports, Exercise &amp; Health Science
                </option>
                <option value="Swedish A: Language &amp; Literature"
                >Swedish A: Language &amp; Literature
                </option>
                <option value="Swedish A: Literature"
                >Swedish A: Literature
                </option>
                <option value="Swedish B"
                >Swedish B
                </option>
                <option value="Theatre">
                    Theatre
                </option>
                <option value="TOK: Essay and Presentation">
                    TOK: Essay and Presentation
                </option>
                <option value="isual Arts">V
                    isual Arts
                </option>
                <option value="World Religions">
                    World Religions
                </option>
      </select>
      {/* You can add more dropdowns like for HL/SL here if needed */}
    </React.Fragment>
  );

  // JSX for Other Subjects dropdown
  const OtherSubjectsDropDown = (index) => (
    <React.Fragment>
      <select onChange={(e) => handleChange(index, e.target.value)} className="form-select form-select-sm" aria-label=".form-select-sm example">
      
      <option value="">
                    Select
                </option>
                <option value="Arabic A: Language &amp; Literature">
                    Arabic A: Language &amp; Literature
                </option>
                <option value="Arabic A: Literature">
                    Arabic A: Literature
                </option>
                <option value="Arabic B">
                    Arabic B
                </option>
                <option value="Art History"
                >Art History
                </option>
                <option value="Biology">
                    Biology
                </option>
                <option value="Business Management (BM)">
                    Business Management (BM)
                </option>
                <option value="Chemistry">
                    Chemistry
                </option>
                <option value="Chinese A: Language">
                    Chinese A: Language
                </option>
                <option value="Chinese B">
                    Chinese B
                </option>
                <option value="Computer Science">
                    Computer Science
                </option>
                <option value="Danish A: Language &amp; Literature"
                >Danish A: Language &amp; Literature
                </option>
                <option value="Danish A: Literature">
                    Danish A: Literature
                </option>
                <option value="Danish B"
                >Danish B
                </option>
                <option value="Design Technology">
                    Design Technology
                </option>
                <option value="Economics">
                    Economics
                </option>
                <option value="English A: Language &amp; Literature">
                    English A: Language &amp; Literature
                </option>
                <option value="English A: Literature">
                    English A: Literature
                </option>
                <option value="English B">
                    English B
                </option>
                <option value="English: Literature &amp; Performance">
                    English: Literature &amp; Performance
                </option>
                <option value="Environmental Systems &amp; Societies (ESS)">
                    Environmental Systems &amp; Societies (ESS)
                </option>
                <option value="Extended Essay">Extended Essay
                </option>
                <option value="Film">
                    Film
                </option>
                <option value="French A: Language">
                    French A: Language
                </option>
                <option value="French A: Language &amp; Literature"
                >French A: Language &amp; Literature
                </option>
                <option value="French B">
                    French B
                </option>
                <option value="Geography">
                    Geography
                </option>
                <option value="German A: Language &amp; Literature"
                >German A: Language &amp; Literature
                </option>
                <option value="German A: Literature">
                    German A: Literature
                </option>
                <option value="German B">
                    German B
                </option>
                <option value="Global Politics">
                    Global Politics
                </option>
                <option value="Global Societies">
                    Global Societies
                </option>
                <option value="Greek (Classical) B">
                    Greek (Classical) B
                </option>
                <option value="History">
                    History
                </option>
                <option value="Information Technology in a Global Society (ITGS)"
                >Information Technology in a Global Society (ITGS)
                </option>
                <option value="Internal Assessment">Internal Assessment
                </option>
                <option value="Italian A: Language &amp; Literature"
                >Italian A: Language &amp; Literature
                </option>
                <option value="Italian A: Literature">
                    Italian A: Literature
                </option>
                <option value="Italian B">
                    Italian B
                </option>
                <option value="Latin B">
                    Latin B
                </option>
                <option value="Maths: Analysis &amp; Approaches (A&amp;A)"
                >Maths: Analysis &amp; Approaches (A&amp;A)
                </option>
                <option value="Maths: Applications &amp; Interpretation (A&amp;I)"
                >Maths: Applications &amp; Interpretation (A&amp;I)
                </option>
                <option value="Philosophy">
                    Philosophy
                </option>
                <option value="Physics">
                    Physics
                </option>
                <option value="Polish A: Language &amp; Literature"
                >Polish A: Language &amp; Literature
                </option>
                <option value="Polish A: Literature"
                >Polish A: Literature
                </option>
                <option value="Polish B"
                >Polish B
                </option>
                <option value="Portuguese A: Language &amp; Literature"
                >Portuguese A: Language &amp; Literature
                </option>
                <option value="Portuguese A: Literature"
                >Portuguese A: Literature
                </option>
                <option value="Portuguese B"
                >Portuguese B
                </option>
                <option value="Social &amp; Cultural Anthropology">
                    Social &amp; Cultural Anthropology
                </option>
                <option value="Spanish A: Language &amp; Literature"
                >Spanish A: Language &amp; Literature
                </option>
                <option value="Spanish A: Literature">
                    Spanish A: Literature
                </option>
                <option value="Spanish B">
                    Spanish B
                </option>
                <option value="Sports, Exercise &amp; Health Science">
                    Sports, Exercise &amp; Health Science
                </option>
                <option value="Swedish A: Language &amp; Literature"
                >Swedish A: Language &amp; Literature
                </option>
                <option value="Swedish A: Literature"
                >Swedish A: Literature
                </option>
                <option value="Swedish B"
                >Swedish B
                </option>
                <option value="Theatre">
                    Theatre
                </option>
                <option value="TOK: Essay and Presentation">
                    TOK: Essay and Presentation
                </option>
                <option value="isual Arts">V
                    isual Arts
                </option>
                <option value="World Religions">
                    World Religions
                </option>
        {/* ...other options */}
      </select>
    </React.Fragment>
  );

  useEffect(() => {
    setNumberOfIBSubjects([IBSubjectsDropDown(0)]);
    setNumberOfOtherSubjects([OtherSubjectsDropDown(0)]);
  }, []);
  // Function to render elements based on the selected program
  const renderElement = () => {
    if (selectedProgram === "IB") {
      return (
        <React.Fragment>
          {numberOfIBSubjects.map((subDrop, i) => {
            return (
              <div className="input-group mb-4" key={i}>
                {subDrop}
                <button className="input-group-text" onClick={() => removeIBSubject(i)}><i className="fa-solid fa-xmark P-3"></i></button>
              </div>
            );
          })}
          <button
            className='btn btn-sm btn-info'
            onClick={AddIBSubjects}
          >
            Add another Subject<i className='fa fa-plus ps-2' />
          </button>
        </React.Fragment>
      );
    } else if (selectedProgram === "MYP" || selectedProgram === "IGCSE") {
      return (
        <React.Fragment>
          {numberOfOtherSubjects.map((subDrop, i) => {
            return (
              <div className="input-group mb-4" key={i}>
                {subDrop}
                <button className="input-group-text" onClick={() => removeOtherSubject(i)}><i className="fa-solid fa-xmark P-3"></i></button>
              </div>
            );
          })}
          <button
            className='btn btn-sm btn-info'
            onClick={AddOtherSubjects}
          >
            Add another Subject<i className='fa fa-plus ps-2' />
          </button>
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      <div className='formContent'>
        <div className='sideSectionMargin mt-5'>
          <div className="homeParaFont mt-4 text-center text-dark">
            <b>
              Well done.<br />
              What subjects do you need a tutor for?
            </b>
          </div>
          <div className='mt-4'>
            {renderElement()}
          </div>
          <div className='d-flex justifyEnd'>
            <button
              className='btn btn-sm btn-primary'
              onClick={() => {
                setStudentForm((prevStudentForm) => ({
                    ...prevStudentForm,
                    Whatsubjectsdoyouneedatutorfor: selectedSubjects,
                    }))
                    
                    selectedCallBack(selectedProgram, 4)}}

            >
              Next
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
