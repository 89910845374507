import React, { useState, useEffect, useRef, useContext } from "react";
import "./home.css";
import ReviewSection from "../../Components/ReviewSection";
import UnderstandIB from "../../Components/UnderstandIB";
import Tutors from "../../Components/TutorsList";
import InvisibleCard from "../../Components/invisibleCard";
import FindATutor from "../../Components/FindATutor";
import PlainHeroSection from "../../Components/PlainHeroSection";
import Backdrop from "@mui/material/Backdrop";
import Logo from "../../images/IBI/IBILogo.png";
import MainImage from "../../Components/PlainHeroSection/MainImage";
import HomeSteps from "../../Components/homeSteps";

import { Helmet } from "react-helmet";
import { MyContext } from "../../Context/MyContext";

const Home = () => {

  const {seoData} = useContext(MyContext)
  const [thisPageSEO, setThisPageSEO] = useState({});

  useEffect(() => {
    if(seoData){
      setThisPageSEO(seoData?.find((e) => e?.pageName === "Home"))
    }
  }, [seoData])

  const invisibleCardInfo = {
    title: "Your Journey to Success - Just 3 Steps Away",
    cardsInfo: [
      {
        icons: "fa-regular fa-comments cardIcon",
        title: "Select Your Tailored Package",
        para: "At IB Innovators, we offer flexible tutoring packages designed to cater to your exact requirements.",
      },
      {
        icons: "fa-regular fa-thumbs-up cardIcon",
        title: "Your Perfect Tutor Match",
        para: "Drawing from our extensive network of tutors, we meticulously match you with the tutor best suited to your needs.",
      },
      {
        icons: "fa-solid fa-chalkboard-user cardIcon",
        title: "Meet Your Tutor - Ignite the Journey",
        para: "Once your perfect tutor match is found, you'll connect with them through an online video call, allowing you to interact and embark on your learning journey.",
      },
    ],
  };

  const tutorsRef = useRef();
  const studentsRef = useRef();


  const createHelmetTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const headTags = Array.from(doc.head.children);
  
    return headTags.map((tag, index) => {
      const tagName = tag.tagName.toLowerCase();
  
      if (tagName === 'title') {
        return <title key={index}>{tag.innerText}</title>;
      } else if (tagName === 'meta') {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <meta key={index} {...attributes} />;
      } else if (tagName === 'link') {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <link key={index} {...attributes} />;
      } else if (tagName === 'script' && tag.type === 'application/ld+json') {
        return <script key={index} type="application/ld+json">{tag.innerText}</script>;
      } else {
        return null;
      }
    });
  };

// console.log("thisPageSEO?.schema", thisPageSEO?.schema)

  return (
    <React.Fragment>

<Helmet>
      {thisPageSEO?.schema && createHelmetTags(thisPageSEO.schema)}
    </Helmet>

      <div>
        {/* <HeroSection /> */}
        <MainImage />
      </div>

    <div style={{flex: 1, margin: '0px auto', maxWidth: '1800px'}}>
      <div className="sideSectionMargin mb-5">
        <UnderstandIB studentsRef={studentsRef} tutorsRef={tutorsRef} />
      </div>

      <div ref={studentsRef} className="sideSectionMargin reviewSection mb-5">
        <ReviewSection />
      </div>

      <div>
        <HomeSteps invisibleCardInfo={invisibleCardInfo} />
      </div>

      <div ref={tutorsRef} className="sideSectionMargin mb-5">
        <Tutors />
      </div>
      </div>
      <div>
        <FindATutor />
      </div>
    </React.Fragment>
  );
};

export default Home;
