import React, { useState, useContext } from 'react'
import { MyContext } from '../../../Context/MyContext';

export default function QueryFormFifthStep(props) {
    const { selectedCallBack, selectedProgram } = props;
    const [fundamentalObjective, setFundamentalObjective] = useState('');
    const { studentForm, setStudentForm } = useContext(MyContext);

    const handleButtonClick = (objective) => {
        // Save the clicked objective to state
        setFundamentalObjective(objective);
        setStudentForm((prevStudentForm) => ({
            ...prevStudentForm,
            fundamentalObjective: objective,
            }))
        // Continue with your existing logic
        selectedCallBack(selectedProgram, 6);
    }

    // Save all the state information into a variable
    const allData = {
        fundamentalObjective
    };

    console.log("All Data:", allData);

    return (
        <div>
            <div className='formContent'>
                <div className='sideSectionMargin mt-5'>
                    <div className="homeParaFont mt-4 text-center text-dark">
                        <b>
                            Certainly! <br />
                            Let's delve into, What's your fundamental objective?
                        </b>
                    </div>

                    <div className="d-grid gap-2 mt-4">
                        <button
                            className="btn btn-outline-info"
                            onClick={() => handleButtonClick("Improve my grade")}
                        >
                            Improve my grade <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => handleButtonClick("Prepare for my final exam")}
                        >
                            Prepare for my final exam <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => handleButtonClick("Boost my confidence")}
                        >
                            Boost my confidence <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => handleButtonClick("Fill in subject gaps")}
                        >
                            Fill in subject gaps <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => handleButtonClick("Catch up after COVID-19 disruption")}
                        >
                            Catch up after COVID-19 disruption <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}
