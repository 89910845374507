import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button } from "@mui/material";

export function RevPageNoOne({
  setPageNo,
  pageNo,
  setActivePage,
  setRegisterModalOpen,
  fullFormData,
  setFullFormData,
  isMobile
}) {
  const navigate = useNavigate();
  useEffect(() => {
    setActivePage(1);
  }, []);

  const [signUpType, setSignUpType] = useState(fullFormData?.userType);

  const handleSignUpTypeChange = (e) => {
    setSignUpType(e.target.value);
  };


  useEffect(() => {
    setFullFormData((prev) => ({
      ...prev,
      userType: signUpType
    }));
  }, [
    signUpType
  ]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (signUpType !== "student" && signUpType !== "parent") {
          alert('Please select an option');
        } else {
          setFullFormData({ ...fullFormData, userType: signUpType });
          setPageNo(pageNo + 1);
        }
      }}
      style={{ flex: 1 }}
    >
      <h3
        style={{ fontSize: isMobile ? "1.1rem" : "1.3rem", marginBottom: "2rem" }}
      >
        Already Have an Account?{" "}
        <span
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => { navigate("/login"); }}
        >
          Log-in
        </span>
      </h3>

      <div style={{ marginBottom: "2rem" }}>
        <label style={{ marginBottom: "5px", fontSize: isMobile ? "1.1rem" : "1.3rem" }}>
          Are you a: <span style={{ color: 'red' }}>*</span>
        </label>

        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={signUpType}
          onChange={handleSignUpTypeChange}
          required
        >
          <FormControlLabel value="student" control={<Radio />} label="Student" />
          <FormControlLabel value="parent" control={<Radio />} label="Parent" />
        </RadioGroup>
      </div>

      <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <Button
          disabled={!signUpType || signUpType===""}
          variant="contained"
          sx={{ background: "#14549f", padding: '5px 20px', fontSize: '1rem' }}
          type="submit"
        >
          Next
        </Button>
      </div>
    </form>
  );
}
