import React from "react";
import styles from "./RevisionCourseForm.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../Context/MyContext";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import RevisionCoursesForm from "../../Components/RevisionCoursesForm/RevisionCoursesForm";

function RevisionCourseForm() {

  const { seoData } = useContext(MyContext);
  const [thisPageSEO, setThisPageSEO] = useState({});

  useEffect(() => {
    if (seoData) {
      setThisPageSEO(seoData?.find((e) => e?.pageName === "Revision Course Form"));
    }
  }, [seoData]);

  const createHelmetTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const headTags = Array.from(doc.head.children);

    return headTags.map((tag, index) => {
      const tagName = tag.tagName.toLowerCase();

      if (tagName === "title") {
        return <title key={index}>{tag.innerText}</title>;
      } else if (tagName === "meta") {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <meta key={index} {...attributes} />;
      } else if (tagName === "link") {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <link key={index} {...attributes} />;
      } else if (tagName === "script" && tag.type === "application/ld+json") {
        return (
          <script key={index} type="application/ld+json">
            {tag.innerText}
          </script>
        );
      } else {
        return null;
      }
    });
  };

  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 1200); // You can adjust the width threshold as needed
  };

  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);


  return (
    <div className={styles.backgroundColor} style={{
      
  background: isMobile ? "#fff" : "#14549f"
    }}>
      <Helmet>
        {thisPageSEO?.schema && createHelmetTags(thisPageSEO.schema)}
      </Helmet>

      <div
        style={{
          maxWidth: "1600px",
          margin: "0 auto",
          flex: 1, display: "flex"
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            margin: isMobile ? "0px" : "5vh",
            marginTop: isMobile ? "1rem" : "5vh",
            boxShadow: isMobile ? "none" : "0px 3px 15px rgba(0, 0, 0, 0.4)",
            minHeight: "600px",
            padding: isMobile ? "10px" : "3rem",
            overflow: 'hidden',
            flex: 1
          }}
        >
          <RevisionCoursesForm />
        </div>
      </div>
    </div>
  );
}

export default RevisionCourseForm;
