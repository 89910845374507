import { useState, useEffect } from "react";
import { MyContext } from "../../Context/MyContext";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { useContext } from "react";
import {
  getDocs,
  collection,
  query,
  where,
  doc,
  updateDoc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "@firebase/storage";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import emailjs from "emailjs-com";
import { toast } from "react-hot-toast";

export default function TutorForm() {
  const { subjectsArray, adminAddNotification } = useContext(MyContext);
  const [open, setOpen] = useState(false);

  const [validated, setValidated] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [curricula, setCurricula] = useState([]);
  const [programmes, setProgrammes] = useState([]);
  const [resume, setResume] = useState(null);
  const [agreed, setAgreed] = useState(false);
  const [agreedDisclaimer, setAgreedDisclaimer] = useState(false);
  const [agreedPrivacyPolicy, setAgreedPrivacyPolicy] = useState(false);
  const [error, setError] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 600); // You can adjust the width threshold as needed
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "60%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxHeight: "90%",
    overflow: "scroll",
  };

  // Listen for window resize events to update isMobile state
  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setError(true);
    } else if (agreed === false || agreedDisclaimer === false || agreedPrivacyPolicy === false) {
      event.preventDefault();
      event.stopPropagation();
      setError(true);
    } else if (resume === null) {
      event.preventDefault();
      event.stopPropagation();
      setError(true);
    } else {
      setValidated(true);

      // Upload the resume to Firebase Storage
      const storageRef = ref(storage, "resumes/" + resume.name);
      await uploadBytes(storageRef, resume);
      const downloadURL = await getDownloadURL(storageRef);

      const formData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        city: city,
        state: state,
        zip: zip,
        subjects: subjects,
        assignments: assignments,
        curricula: curricula,
        programmes: programmes,
        resume: downloadURL,
        submittedAt: new Date(),
      };

      const tableRows = Object.entries(formData)
        .map(([key, value]) => {
          if (typeof value === "object" && !Array.isArray(value)) {
            value = JSON.stringify(value);
          } else if (Array.isArray(value)) {
            value = value.join(", ");
          }
          return `
              <tr>
                  <td>${key}</td>
                  <td>${value}</td>
              </tr>
          `;
        })
        .join("");

      console.log("Form Data:", formData);
      setError(false);

      // POST DATA
      const ordersRef = collection(db, "adminPanel");
      const customDocRef = doc(ordersRef, "teacherForm");
      const teacherCollectionRef = collection(customDocRef, "teacherForm");

      try {
        // Create the document and get the generated ID
        const docRef = await addDoc(teacherCollectionRef, formData);
        const id = docRef.id;

        // Update the document to include the ID
        await updateDoc(docRef, { id: id });
        await adminAddNotification(
          `Tutor Form was just submitted by ${formData?.email}`
        );

        const serviceId = process.env.REACT_APP_EMAILSERVICEID;
        const templateId = process.env.REACT_APP_EMAILTEMPLATEID;
        const userId = process.env.REACT_APP_EMAILUSERID;

        const emailParams = {
          from_name: "IBInnovators",
          to_name: "IBInnovators",
          send_to: "hr@ibinnovators.com", // Replace with your recipient's email address
          subject: "New Tutor Form Submission",
          message: `
        <html>
        <head>
        </head>
        <body>
        <p>A person just submitted Tutor Form:</p>
        <table style="border: 1px solid #000; border-collapse: collapse; width: 100%;">
        <tbody>
           ${tableRows}
        </body>
        </html>
        
    `,
        };

        emailjs
          .send(serviceId, templateId, emailParams, userId)
          .then((response) => {
            setShowModal(true);
          })
          .catch((error) => {
            console.error("Error sending email");
          });

        toast.success("Form submitted successfully");
      } catch (error) {
        toast.error("Error submitting form");
      }
    }
  };

  const handleSubjectsCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Add the selected subject to the array
      setSubjects((prevSubjects) => [...prevSubjects, value]);
    } else {
      // Remove the deselected subject from the array
      setSubjects((prevSubjects) =>
        prevSubjects.filter((subject) => subject !== value)
      );
    }
  };

  const handleAssignmentsCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Add the selected subject to the array
      setAssignments((prevSubjects) => [...prevSubjects, value]);
    } else {
      // Remove the deselected subject from the array
      setAssignments((prevSubjects) =>
        prevSubjects.filter((subject) => subject !== value)
      );
    }
  };

  const handleCurriculaCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Add the selected subject to the array
      setCurricula((prevSubjects) => [...prevSubjects, value]);
    } else {
      // Remove the deselected subject from the array
      setCurricula((prevSubjects) =>
        prevSubjects.filter((subject) => subject !== value)
      );
    }
  };

  const handleProgrammesCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Add the selected subject to the array
      setProgrammes((prevSubjects) => [...prevSubjects, value]);
    } else {
      // Remove the deselected subject from the array
      setProgrammes((prevSubjects) =>
        prevSubjects.filter((subject) => subject !== value)
      );
    }
  };

  return (
    <div className="tutorForm mb-5">
      <div className="homePageHeadings text-center textTheme">
        <div>Academic Tutor at IBI</div>
      </div>
      <div className="p-5 border mt-3">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>First name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="First name"
                size="sm"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Last name"
                size="sm"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomEmail">
              <Form.Label>Email</Form.Label>
              <InputGroup hasValidation size="sm">
                <Form.Control
                  size="sm"
                  type="email"
                  placeholder="Email"
                  aria-describedby="inputGroupPrepend"
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationCustom03">
              <Form.Label>City</Form.Label>
              <Form.Control
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                type="text"
                placeholder="City"
                size="sm"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid city.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationCustom04">
              <Form.Label>State</Form.Label>
              <Form.Control
                onChange={(e) => {
                  setState(e.target.value);
                }}
                type="text"
                placeholder="State"
                size="sm"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid state.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationCustom05">
              <Form.Label>Zip</Form.Label>
              <Form.Control
                onChange={(e) => {
                  setZip(e.target.value);
                }}
                type="text"
                placeholder="Zip"
                size="sm"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid zip.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              as={Col}
              md="12"
              controlId="validationCustom05"
              className="mt-4"
            >
              <Form.Label>
                <b>IB Subjects you teach? (select all that apply)</b>{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <div className="row mt-4">
                {subjectsArray.map((item, index) => (
                  <div className="col-md-4">
                    <Form.Check
                      default
                      label={item}
                      value={item}
                      name="group1"
                      type={"checkbox"}
                      id={`default-checkbox-${index}`}
                      onChange={handleSubjectsCheckboxChange}
                    />
                  </div>
                ))}
              </div>
            </Form.Group>

            <Form.Group
              as={Col}
              md="12"
              controlId="validationCustom05"
              className="mt-4"
            >
              <Form.Label>
                <b>Assignments you teach? (select all that apply)</b>{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <div className="row mt-4 ">
                <div className="col-md-4">
                  <Form.Check
                    default
                    label="Internal Assessment (IA)"
                    value="Internal Assessment (IA)"
                    name="group1"
                    type={"checkbox"}
                    id={`default-checkbox-51`}
                    onChange={handleAssignmentsCheckboxChange}
                  />
                </div>
                <div className="col-md-4">
                  <Form.Check
                    default
                    label="Individual Oral (IO)"
                    value="Individual Oral (IO)"
                    name="group1"
                    type={"checkbox"}
                    id={`default-checkbox-52`}
                    onChange={handleAssignmentsCheckboxChange}
                  />
                </div>
                <div className="col-md-4">
                  <Form.Check
                    default
                    label="Extended Essay (EE)"
                    value="Extended Essay (EE)"
                    name="group1"
                    type={"checkbox"}
                    id={`default-checkbox-53`}
                    onChange={handleAssignmentsCheckboxChange}
                  />
                </div>
                <div className="col-md-4">
                  <Form.Check
                    default
                    label="Personal Project MYP"
                    value="Personal Project MYP"
                    name="group1"
                    type={"checkbox"}
                    id={`default-checkbox-54`}
                    onChange={handleAssignmentsCheckboxChange}
                  />
                </div>
                <div className="col-md-4">
                  <Form.Check
                    default
                    label="HL Essay"
                    value="HL Essay"
                    name="group1"
                    type={"checkbox"}
                    id={`default-checkbox-55`}
                    onChange={handleAssignmentsCheckboxChange}
                  />
                </div>
                <div className="col-md-4">
                  <Form.Check
                    default
                    label="None"
                    value="None"
                    name="group1"
                    type={"checkbox"}
                    id={`default-checkbox-56`}
                    onChange={handleAssignmentsCheckboxChange}
                  />
                </div>
              </div>
            </Form.Group>

            <Form.Group
              as={Col}
              md="12"
              controlId="validationCustom05"
              className="mt-4"
            >
              <Form.Label>
                <b>Curricula taught (select all that apply)</b>{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <div className="row mt-4">
                <div className="col-md-3">
                  <Form.Check
                    default
                    label="IBDP"
                    value="IBDP"
                    name="group1"
                    type={"checkbox"}
                    id={`default-checkbox-57`}
                    onChange={handleCurriculaCheckboxChange}
                  />
                </div>
                <div className="col-md-3">
                  <Form.Check
                    default
                    label="MYP"
                    value="MYP"
                    name="group1"
                    type={"checkbox"}
                    id={`default-checkbox-58`}
                    onChange={handleCurriculaCheckboxChange}
                  />
                </div>
                <div className="col-md-3">
                  <Form.Check
                    default
                    label="IGCSE"
                    value="IGCSE"
                    name="group1"
                    type={"checkbox"}
                    id={`default-checkbox-59`}
                    onChange={handleCurriculaCheckboxChange}
                  />
                </div>
              </div>
            </Form.Group>

            <Form.Group controlId="formFileSm" className="mb-3 mt-4">
              <Form.Label>
                <b>Resume/CV</b> <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                onChange={(e) => {
                  setResume(e.target.files[0]);
                }}
                type="file"
                size="sm"
              />
            </Form.Group>
          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              label={
                <>
                  I agree to{" "}
                  <a
                    href="https://www.ibinnovators.com/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </>
              }
              feedback="You must agree before submitting."
              feedbackType="invalid"
              id={`default-checkbox-60`}
              onChange={(event) => {
                const checked = event.target.checked;
                setAgreed(checked);
              }}
            />
            
            <Form.Check
              label={
                <>
                  I have read and understood the{" "}
                  <a
                    href="https://www.ibinnovators.com/disclaimer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Disclaimer
                  </a>
                </>
              }
              feedback="You must agree before submitting."
              feedbackType="invalid"
              id={`default-checkbox-70`}
              onChange={(event) => {
                const checked = event.target.checked;
                setAgreedDisclaimer(checked);
              }}
            />
            
            <Form.Check
              label={
                <>
                  I accept the{" "}
                  <a
                    href="https://www.ibinnovators.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </>
              }
              feedback="You must agree before submitting."
              feedbackType="invalid"
              id={`default-checkbox-90`}
              onChange={(event) => {
                const checked = event.target.checked;
                setAgreedPrivacyPolicy(checked);
              }}
            />
          </Form.Group>
          <button
            type="submit"
            style={{ borderTopLeftRadius: "0px", padding: "0.5rem" }}
            className="mainButton"
          >
            Submit form
          </button>
          {error && (
            <div style={{ color: "red" }}>Please fill out all the fields.</div>
          )}
        </Form>
      </div>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: "9999999",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "300px",
              padding: "20px",
              borderRadius: "10px",
              backgroundColor: "#fff",
              textAlign: "center",
              boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h2
              style={{
                margin: "0 0 20px",
                color: "#333",
                fontSize: "24px",
              }}
            >
              Thank You!
            </h2>
            <p
              style={{
                margin: "0 0 20px",
                color: "#555",
                fontSize: "16px",
              }}
            >
              We have received your form and will be in contact with you soon.
            </p>
            <button
              onClick={() => {
                setShowModal(false);
                navigate("/", { replace: true });
              }}
              style={{
                padding: "10px 20px",
                backgroundColor: "rgb(156, 16, 16)",
                border: "none",
                borderRadius: "5px",
                color: "#fff",
                fontSize: "16px",
              }}
            >
              OK
            </button>
          </div>
        </div>
      )}

    </div>
  );
}
