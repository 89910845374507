import React from 'react'
import './index.css'

export default function ComingSoon() {
    return (
        <div >
            <h1 className='comingSoonPage'>This Page Will Launch Soon!</h1>
        </div>
    )
}
