import React from "react";
import "./index.css";
import reviewProfile from "../../../images/reviewCard.png";

export default function ReviewCard(props) {
  const { face, name, rating, from, content, backside } = props;
  const ratingStar = (index) => {
    if (index <= rating) {
      return (
        <li key={index}>
          <i className="fa fa-star text-warning homePageParaFont"></i>
        </li>
      );
    } else {
      return (
        <li key={index}>
          <i className="fa fa-star text-secondary homePageParaFont"></i>
        </li>
      );
    }
  };

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        alignItems: "center",
        minHeight: "32rem",
        height: "max-content",
        overflow: "hidden",
        padding: "10px",
        border: "none",
        zIndex: 9999,
      }}
      className="card text-dark"
    >
      <div
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          background: backside ? "rgba(14, 56, 136, 0.9)" : "fff",
          color: backside ? "#fff" : "black"
        }}
        className="card-body"
      >
        <div>
          {(face === "front" || face === "both") && (
            <div className="d-flex justify-content-center mb-2">
              <img
                src={reviewProfile}
                className="rounded-circle shadow-1-strong"
                width="200"
                height="200"
                alt="SomeImage"
              />
            </div>
          )}
          {name && (
            <div
              style={{ fontSize: "1.8rem" }}
              className="font-weight-bold text-center"
            >
              {name}
            </div>
          )}

          {from && (
            <div className=" homePageParaCardFront">
              <i className="fa-solid fa-location-dot me-1"></i>
              <span style={{ fontSize: "1.3rem" }} className="text-center">
                {from}
              </span>
            </div>
          )}

          {rating && (
            <ul className="list-unstyled d-flex justify-content-center">
              {[1, 2, 3, 4, 5].map((index) => ratingStar(index))}

              <li className="homePageParaFont">&nbsp;&nbsp;{rating}/5</li>
            </ul>
          )}

          <p
            style={{
              fontSize: "1.2rem",
              height: backside ? "100%" : "max-content",
              borderRadius: backside ? "15px" : "none",
              display: backside ? "flex" : "block",
              alignItems: backside ? "center" : "unset", // Align vertically to center on the backside
              textAlign: 'justify', marginRight: '10px', marginLeft: '10px'
            }}
            className="mb-2 "
          >
            {content}
            {face === "front" && <button>Review Feedback</button>}
          </p>
        </div>
      </div>
    </div>
  );
}
