import React, { useState, useEffect, useContext } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import emailjs from "emailjs-com";
import { db } from "../../firebase";
import { MyContext } from "../../Context/MyContext";

import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  Container,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import toast from "react-hot-toast";
import { GuidanceAndSupport } from "./GuidanceAndSupport";
import { useLocation } from "react-router-dom";

export function RevPageNoFive({
  setRegisterModalOpen,
  setPageNo,
  pageNo,
  setActivePage,
  fullFormData,
  setFullFormData,
  isMobile,
  setShowImage,
}) {
  useEffect(() => {
    setActivePage(6);
    setShowImage(true);
  }, []);

  const { adminAddNotification } = useContext(MyContext);
  const number = fullFormData?.seekingTutoringFor?.length;
  const formattedNumber = number < 10 ? `0${number}` : number;

  const location = useLocation();
  console.log("hash", location.hash?.slice(1).split("%20").join(" "));

  const queries = [
    {
      select: "Support for Independent Study - Boost my grip on subject(s)",
      level: "Foundation",
      diploma: "IB Diploma Graduates",
      journey: "Self Starter",
    },
    {
      select: "Overcome IB Challenges - Improve my IB grade",
      level: "Advance",
      diploma: "IB Worldschool Teachers",
      journey: "Aspiring Achiever",
    },
    {
      select: "Striving for deep understanding - Exam Prep",
      level: "Expert",
      diploma: "Certified IB Examiners",
      journey: "High-Grade Seeker",
    },
  ];

  const tutoringPlans = [
    {
      name: "Lesson Plan (08 hours)",
      hours: 8,
      pricing: [
        {
          level: "Foundation",
          price: 285,
        },
        {
          level: "Advance",
          price: 390,
        },
        {
          level: "Expert",
          price: 455,
        },
      ],
    },
    {
      name: "Lesson Plan (12 hours)",
      hours: 12,
      pricing: [
        {
          level: "Foundation",
          price: 430,
        },
        {
          level: "Advance",
          price: 585,
        },
        {
          level: "Expert",
          price: 685,
        },
      ],
    },
    {
      name: "Lesson Plan (16 hours)",
      hours: 16,
      pricing: [
        {
          level: "Foundation",
          price: 575,
        },
        {
          level: "Advance",
          price: 780,
        },
        {
          level: "Expert",
          price: 910,
        },
      ],
    },

    {
      name: "Pay As You Go",
      hours: null,
      pricing: [
        {
          level: "Foundation",
          price: 39.99,
        },
        {
          level: "Advance",
          price: 53.99,
        },
        {
          level: "Expert",
          price: 62.99,
        },
      ],
    },
  ];

  const [objectiveTitle, setObjectiveTitle] = useState(
    fullFormData?.objectiveTitle || queries[0]?.select
  );
  const [objective, setObjective] = useState(
    fullFormData?.objective || queries[0]
  );
  const [tutoringPlanTitle, setTutoringPlanTitle] = useState(
    fullFormData?.tutoringPlanTitle || tutoringPlans[0]?.name
  );
  const [tutoringPlan, setTutoringPlan] = useState(
    fullFormData?.tutoringPlan || tutoringPlans[0]
  );
  const [hoursNeeded, setHoursNeeded] = useState(
    fullFormData?.hoursNeeded || null
  );
  const [price, setPrice] = useState(fullFormData?.price || 0);

  const payAsYouGoPricingCalculator = () => {
    const pricePerHour =
      tutoringPlan?.pricing?.find((f) => f?.level === objective?.level)
        ?.price || 0;
    const finalPrice = hoursNeeded * pricePerHour * number;
    setPrice(finalPrice.toFixed(2));
  };

  const lessonPlanPricingCalculator = () => {
    const finalPrice =
      tutoringPlan?.pricing?.find((f) => f?.level === objective?.level)
        ?.price || 0;
    setPrice(finalPrice.toFixed(2));
  };

  useEffect(() => {
    if (tutoringPlanTitle === "Pay As You Go") {
      payAsYouGoPricingCalculator();
    } else {
      lessonPlanPricingCalculator();
    }
  }, [
    tutoringPlanTitle,
    hoursNeeded,
    tutoringPlan,
    objective,
    objectiveTitle,
    fullFormData,
  ]);

  useEffect(() => {
    setFullFormData((prev) => ({
      ...prev,
      objective,
      tutoringPlan,
      hoursNeeded,
      price,
      objectiveTitle,
      tutoringPlanTitle,
    }));
  }, [
    objective,
    tutoringPlan,
    hoursNeeded,
    price,
    objectiveTitle,
    tutoringPlanTitle,
  ]);

  function handleSubmit() {
    if (price > 0 || price !== "") {
      setFullFormData({
        ...fullFormData,
        objective,
        tutoringPlan,
        hoursNeeded,
        price,
        objectiveTitle,
        tutoringPlanTitle,
      });
      setPageNo((prev) => prev + 1);
    }
  }

  useEffect(() => {
    const hash = location.hash?.slice(1).split("%20").join(" ");

    if (hash === "Lesson Plan (08 hours)" || hash === "Pay As You Go") {
      setTutoringPlanTitle(tutoringPlans?.find((f) => f?.name === hash)?.name);
      setTutoringPlan(tutoringPlans?.find((f) => f?.name === hash));
    }
  }, [location.hash]);


// ________________HIGHLIGHT LOGIC______________________________

const [animationClass, setAnimationClass] = useState('');

useEffect(() => {
  setAnimationClass('');

  // Remove the animation class after 1.5 seconds to allow re-triggering on subsequent changes
  const timer = setTimeout(() => {
    setAnimationClass('highlight-transition');
  }, 0);

  // Clean up the timeout on unmount or re-triggering to prevent memory leaks
  return () => clearTimeout(timer);
}, [objective?.level, objective?.diploma, objective?.journey]);

  return (
    <Form onSubmit={handleSubmit}>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          gap: "10px",
          borderBottom: "1px solid #ddd",
          paddingBottom: "1rem",
        }}
      >
        <div>No of Subjects Selected</div>
        <div>
          <b>{formattedNumber}</b>
        </div>
      </div>

      {/* ____________________________________ */}

      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginTop: "1rem",
          justifyContent: "space-between",
          flexWrap: isMobile ? "wrap" : "nowrap",
        }}
      >
        <div>Choose your tutoring objective with IBI</div>

        <FormControl>
          <InputLabel>Objective</InputLabel>
          <Select
            required
            value={objectiveTitle}
            label="Objective"
            style={{width: "100%", maxWidth: isMobile ? "250px" : "100%"}}
            onChange={(e) => {
              setObjectiveTitle(e.target.value);
              setObjective(queries?.find((f) => f?.select === e.target.value));
            }}
          >
            {queries.map((item, index) => (
              <MenuItem key={index} value={item?.select}>
                {item?.select}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div
      style={{
        marginTop: "1rem",
        borderBottom: "1px solid #ddd",
        paddingBottom: "1rem",
      }}
    >
      <Typography className={animationClass}>
        Our <b>{objective?.level}</b> level <b>{objective?.diploma}</b> will
        help guide you through your IB journey. Based on your tutoring support
        need you are <b>{objective?.journey}</b>.
      </Typography>
    </div>

      {/* ____________________________________ */}

      <div
        style={
          tutoringPlanTitle === "Pay As You Go"
            ? {
                flex: 1,
                display: "flex",
                flexWrap: isMobile ? "wrap" : "nowrap",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
                marginTop: "0.5rem",
                padding: '0.5rem'
              }
            : {
                flex: 1,
                display: "flex",
                flexWrap: isMobile ? "wrap" : "nowrap",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
                marginTop: "0.5rem",
                borderBottom: "1px solid #ddd",
                padding: '0.5rem'
              }
        }
      >
        <div>Select your tutoring plan</div>

        <div>
          <FormControl style={{ minWidth: "250px" }}>
            <InputLabel>Tutoring Plan</InputLabel>
            <Select
              required
              value={tutoringPlanTitle}
              label="Tutoring Plan"
              onChange={(e) => {
                setTutoringPlanTitle(e.target.value);
                setTutoringPlan(
                  tutoringPlans?.find((f) => f?.name === e.target.value)
                );
              }}
            >
              {tutoringPlans.map((item, index) => (
                <MenuItem key={index} value={item?.name}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      {tutoringPlanTitle === "Pay As You Go" && (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexWrap: isMobile ? "wrap" : "nowrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
            borderBottom: "1px solid #ddd",
            background: '#eee',
            padding: '0.5rem',
          }}
        >
          <Typography >How many hours you'll be needing per subject:</Typography>
          <TextField
            required
            style={{ minWidth: "250px" }}
            value={hoursNeeded}
            onChange={(e) => {
              if (e.target.value >= 0) {
                setHoursNeeded(e.target.value);
              } else {
                toast.error("Please enter a number above 0");
              }
            }}
            type="number"
            label="Hours Needed"
          />
        </div>
      )}

      {/* ____________________________________ */}

      <div style={{ marginTop: "2rem" }}>
        <div style={{fontSize: '1.2rem', marginBottom: '10px'}}> 
          <b>Review your Session Pricing:</b>
        </div>
        <div>
          <b>One-on-One Tutoring Session</b>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          <div>Tutor Support Type</div>
          <div>{objective?.diploma}</div>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          <div>{tutoringPlanTitle}</div>
          <div>
            {
              tutoringPlan?.pricing?.find((f) => f?.level === objective?.level)
                ?.price
            }{" "}GBP{" "}
            {tutoringPlanTitle === "Pay As You Go" && "per hourly session"}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          <div>Subjects</div>
          <div>
            <b>{formattedNumber}</b>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            gap: "10px",
            justifyContent: "space-between",
            paddingBottom: "0.5rem",
            borderBottom: "1px solid #6e6e6e",
          }}
        >
          <div>Total Credits</div>
          <div>
            <b>
              {tutoringPlan?.hours !== null
                ? `${tutoringPlan?.hours} hours for all subjects`
                : `${
                    hoursNeeded < 10
                      ? hoursNeeded === null
                        ? 0
                        : `0${hoursNeeded}`
                      : hoursNeeded
                  }`}
            </b>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            gap: "10px",
            justifyContent: "space-between",
            padding: "0.5rem 0rem",
            borderBottom: "1px solid #6e6e6e",
            fontWeight: "bolder",
          }}
        >
          <div>Total Price (£)</div>
          <div>{price} GBP</div>
        </div>

          {
            tutoringPlanTitle !== "Pay As You Go" &&
        <div style={{marginTop: '10px'}}>
        <span style={{color: 'red'}}>*</span> For lesson plans, your number of hours will be apportioned among your chosen subjects as per your requirement
        </div>
          }
      </div>

      <GuidanceAndSupport
        setShowImage={setShowImage}
        setRegisterModalOpen={setRegisterModalOpen}
        setPageNo={setPageNo}
        pageNo={pageNo}
        setActivePage={setActivePage}
        fullFormData={fullFormData}
        setFullFormData={setFullFormData}
        isMobile={isMobile}
      />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: "10px",
          justifyContent: "flex-end",
          mt: 4,
          flexWrap: "wrap",
        }}
      >
        <Button
          variant="outlined"
          // color="error"
          sx={{ fontSize: "1rem" }}
          type="button"
          onClick={() => {
            setPageNo(pageNo - 1);
          }}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          sx={{
            background: "#14549f",
            padding: "5px 20px",
            fontSize: "1rem",
          }}
          disabled={
            price <= 0 ||
            price === "" ||
            !fullFormData?.wantGuidanceAndSupport ||
            fullFormData?.wantGuidanceAndSupport === "" ||
            (fullFormData?.wantGuidanceAndSupport === "yes" &&
              fullFormData?.guidancePrice <= 0)
          }
          type="submit"
        >
          Next
        </Button>
      </Box>

      {/* <Form onSubmit={handleSubmit}>
        <Form.Group
          as={Col}
          md="12"
          controlId="validationCustom05"
          className="mt-4"
        >
          <Form.Label style={{ fontSize: "1.3rem" }}>
            What's your fundamental objective?{" "}
            <span className="text-danger">*</span>
          </Form.Label>
          <Grid container spacing={0} style={{ flex: 1, minWidth: "300px" }}>
            {[
              "Improve my Grade",
              "Prepare for my Final Exams",
              "Boost my Grip on Subjects",
              "Fill in Subject Gaps",
              "Other",
            ].map((label, index) => (
              <Grid item xs={12} sm={isMobile ? 12 : 6} key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={objective.includes(label)}
                      onChange={handleCurriculaCheckboxChange}
                      value={label}
                    />
                  }
                  label={label}
                  style={{ fontSize: "1.2rem" }}
                />
                {label === "Other" && objective.includes("Other") && (
                  <TextField
                    style={{ fontSize: "1.2rem" }}
                    onChange={(e) => {
                      setOtherText(e.target.value);
                    }}
                    required
                    type={"text"}
                    label="Enter here"
                    fullWidth
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Form.Group>

        <Form.Group
          as={Col}
          md="12"
          controlId="validationCustom05"
          className="mt-4"
        >
          <Form.Label style={{ fontSize: "1.3rem" }}>
            Do you also want guidance and support for your internal assessment
            or extended essay for your chosen subjects?{" "}
            <span className="text-danger">*</span>
          </Form.Label>
          <RadioGroup
            aria-label="guidance and support"
            name="guidanceAndSupport"
            value={wantGuidanceAndSupport}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </Form.Group>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            mt: 4,
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="outlined"
            // color="error"
            sx={{ fontSize: "1rem" }}
            type="button"
            onClick={() => {
              setPageNo(pageNo - 1);
            }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#14549f",
              padding: "5px 20px",
              fontSize: "1rem",
            }}
            disabled={objective?.length === 0 || wantGuidanceAndSupport === ""}
            type="submit"
          >
            Next
          </Button>
        </Box>
      </Form> */}
    </Form>
  );
}
