import React, { useState, useEffect, useContext } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import emailjs from "emailjs-com";
import { db } from "../../firebase";
import { MyContext } from "../../Context/MyContext";

import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import toast from "react-hot-toast";

export function GuidanceAndSupport({
  setPageNo,
  pageNo,
  setActivePage,
  fullFormData,
  setFullFormData,
  isMobile,
}) {
  useEffect(() => {
    setActivePage(6);
  }, []);

  const subjectsArray = [
    "Biology (HL)",
    "Biology (SL)",
    "Business Management (HL)",
    "Business Management (SL)",
    "Chemistry (HL)",
    "Chemistry (SL)",
    "Computer science (HL)",
    "Computer science (SL)",
    "Economics (HL)",
    "Economics (SL)",
    "Environmental systems and societies (HL)",
    "Environmental systems and societies (SL)",
    "Mathematics: Analysis and approaches (HL)",
    "Mathematics: Analysis and approaches (SL)",
    "Mathematics: Applications and interpretation (HL)",
    "Mathematics: Applications and interpretation (SL)",
    "Physics (HL)",
    "Physics (SL)",
    "Psychology (HL)",
    "Psychology (SL)",
  ];

  const queries = [
    {
      select: "Engage in collaborative peer review sessions",
      level: "Foundation",
      diploma: "IB Diploma Graduates",
      journey: "Collaborative Learner",
    },
    {
      select: "Explore innovative approaches for assessments",
      level: "Advance",
      diploma: "IB Worldschool Teachers",
      journey: "Innovative explorer",
    },
    {
      select: "Focus on practical real-world applications",
      level: "Expert",
      diploma: "Certified IB Examiners",
      journey: "Practical Implementer",
    },
  ];

  const guidancePlan = {
    name: "Pay As You Go",
    pricing: [
      {
        level: "Foundation",
        price: 40.99,
      },
      {
        level: "Advance",
        price: 55.99,
      },
      {
        level: "Expert",
        price: 65.99,
      },
    ],
  };

  const [guidanceAndSupportSubjects, setGuidanceAndSupportSubjects] = useState(
    fullFormData?.guidanceAndSupportSubjects || []
  );

  const [guidanceObjectiveTitle, setGuidanceObjectiveTitle] = useState(
    fullFormData?.guidanceObjectiveTitle || queries[0]?.select
  );
  const [guidanceObjective, setGuidanceObjective] = useState(
    fullFormData?.guidanceObjective || queries[0]
  );

  const [guidanceHoursNeeded, setGuidanceHoursNeeded] = useState(
    fullFormData?.guidanceHoursNeeded || null
  );

  const [guidancePrice, setGuidancePrice] = useState(
    fullFormData?.guidancePrice || 0
  );

  const [otherChecked, setOtherChecked] = useState(false);
  const [otherText, setOtherText] = useState("");

  const handleSelectChange = (event) => {
    const value = event.target.value;
    if (!guidanceAndSupportSubjects.includes(value)) {
      setGuidanceAndSupportSubjects([...guidanceAndSupportSubjects, value]);
    }
  };

  const handleDelete = (subjectToDelete) => {
    setGuidanceAndSupportSubjects((subjects) =>
      subjects.filter((subject) => subject !== subjectToDelete)
    );
  };

  const handleOptionChange = (event) => {
    const { checked } = event.target;
    setOtherChecked(checked);
    if (!checked) setOtherText("");
  };

  const [wantGuidanceAndSupport, setWantGuidanceAndSupport] = useState(
    fullFormData?.wantGuidanceAndSupport || ""
  );

  const handleRadioChange = (event) => {
    setWantGuidanceAndSupport(event.target.value);
  };

  const GuidancePricingCalculator = () => {
    const pricePerHour =
      guidancePlan?.pricing?.find((f) => f?.level === guidanceObjective?.level)
        ?.price || 0;
    const finalPrice =
      guidanceHoursNeeded * pricePerHour * guidanceAndSupportSubjects?.length;
    setGuidancePrice(finalPrice.toFixed(2));
  };

  useEffect(() => {
    GuidancePricingCalculator();
  }, [
    guidancePlan,
    guidanceObjective,
    guidanceHoursNeeded,
    guidanceAndSupportSubjects,
  ]);

  useEffect(() => {
    setFullFormData((prev) => ({
      ...prev,
      guidanceAndSupportSubjects,
      guidanceObjectiveTitle,
      guidanceObjective,
      guidanceHoursNeeded,
      guidancePrice,
      wantGuidanceAndSupport,
    }));
  }, [
    guidanceAndSupportSubjects,
    guidanceObjectiveTitle,
    guidanceObjective,
    guidanceHoursNeeded,
    guidancePrice,
    wantGuidanceAndSupport,
  ]);

  
// ________________HIGHLIGHT LOGIC______________________________

const [animationClass, setAnimationClass] = useState('');

useEffect(() => {
  setAnimationClass('');

  // Remove the animation class after 1.5 seconds to allow re-triggering on subsequent changes
  const timer = setTimeout(() => {
    setAnimationClass('highlight-transition');
  }, 0);

  // Clean up the timeout on unmount or re-triggering to prevent memory leaks
  return () => clearTimeout(timer);
}, [guidanceObjective?.level, guidanceObjective?.diploma, guidanceObjective?.journey]);


  return (
    <Form
      style={{
        marginTop: "2rem",
        borderTop: "1px solid #ddd",
        padding: "2rem 0rem",
      }}
    >
      <Form.Group as={Col} md="12" controlId="validationCustom05">
        <Form.Label  style={{ fontSize: "1.2rem" }}>
          Do you also want guidance and support for your internal assessment or
          extended essay for your chosen subjects?{" "}
          <span className="text-danger">*</span>
        </Form.Label>
        <RadioGroup
          aria-label="guidance and support"
          name="guidanceAndSupport"
          value={wantGuidanceAndSupport}
          onChange={handleRadioChange}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </Form.Group>

      {wantGuidanceAndSupport === "yes" && (
        <>
          <div
            style={{
              marginTop: "1rem",
              padding: "1rem 0rem",
              borderTop: "1px solid #ddd",
              borderBottom: "1px solid #ddd",
            }}
          >
            <div>Select Subject(s)</div>

            <div style={{ minWidth: "300px" }}>
              {otherChecked ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    gap: "10px",
                  }}
                >
                  <TextField
                    fullWidth
                    value={otherText}
                    onChange={(e) => {
                      setOtherText(e.target.value);
                    }}
                    label="Enter other subject name"
                    sx={{ mt: 1 }}
                  />
                  <Button
                    disabled={otherText === ""}
                    variant="outlined"
                    style={{ height: "50px" }}
                    onClick={() => {
                      setGuidanceAndSupportSubjects((prev) => [
                        ...prev,
                        otherText,
                      ]);
                      setOtherText("");
                    }}
                  >
                    Add
                  </Button>
                </div>
              ) : (
                <>
                  <FormControl fullWidth sx={{ mt: 1 }}>
                    <InputLabel>Select Subject</InputLabel>
                    <Select
                      label="Select Subject"
                      onChange={handleSelectChange}
                      inputProps={{ "aria-label": "Select Subject" }}
                    >
                      {subjectsArray.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={otherChecked}
                      onChange={handleOptionChange}
                      value="Other"
                    />
                  }
                  label="Other"
                />
              </FormGroup>

              <Box mt={1}>
                {guidanceAndSupportSubjects.map((item) => (
                  <Chip
                    key={item}
                    label={item}
                    onDelete={() => handleDelete(item)}
                    color="primary"
                    style={{ margin: "5px", background: "#3e3e3e" }}
                  />
                ))}
              </Box>
            </div>
          </div>

          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              gap: "10px",
              borderBottom: "1px solid #ddd",
              paddingBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            <div>No of Subjects Selected</div>
            <div>
              <b>
                {guidanceAndSupportSubjects?.length < 10
                  ? `0${guidanceAndSupportSubjects?.length}`
                  : guidanceAndSupportSubjects?.length}
              </b>
            </div>
          </div>

          {/* ____________________________________ */}

          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "1rem",
              justifyContent: "space-between",
              flexWrap: isMobile ? "wrap" : "nowrap",
            }}
          >
            <div>Choose your tutoring guidance objective with IBI</div>

            <FormControl>
              <InputLabel>Guidance Objective</InputLabel>
              <Select
                required
                value={guidanceObjectiveTitle}
                label="guidanceObjective"
                style={{width: "100%", maxWidth: isMobile ? "250px" : "100%"}}
                onChange={(e) => {
                  setGuidanceObjectiveTitle(e.target.value);
                  setGuidanceObjective(
                    queries?.find((f) => f?.select === e.target.value)
                  );
                }}
              >
                {queries.map((item, index) => (
                  <MenuItem key={index} value={item?.select}>
                    {item?.select}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              marginTop: "1rem",
              borderBottom: "1px solid #ddd",
              paddingBottom: "1rem",
            }}
          >
            <Typography  className={animationClass}>
              Our <b>{guidanceObjective?.level}</b> level{" "}
              <b>{guidanceObjective?.diploma}</b> will help guide you through
              your IB journey. Based on your guidance and support need you are{" "}
              <b>{guidanceObjective?.journey}</b>.
            </Typography>
          </div>

          <div
            style={{
              flex: 1,
              display: "flex",
              flexWrap: isMobile ? "wrap" : "nowrap",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
              padding: "1rem 0.5rem",
              borderBottom: "1px solid #ddd",
              background: '#eee'
            }}
          >
            <Typography>
              How many hours of guidance and support you'll need per subject:
            </Typography>
            <TextField
              required
              style={{ minWidth: "250px" }}
              value={guidanceHoursNeeded}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  setGuidanceHoursNeeded(e.target.value);
                } else {
                  toast.error("Please enter a number above 0");
                }
              }}
              type="number"
              label="Hours Needed"
            />
          </div>

          {/* ____________________________________ */}

          <div style={{ marginTop: "2rem" }}>
          <div style={{fontSize: '1.2rem', marginBottom: '10px'}}> 
              <b>Review your Session Pricing:</b>
            </div>
            <div>
              <b>One-on-One Guidance and Support Session</b>
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              <div>Tutor Support Type</div>
              <div>{guidanceObjective?.diploma}</div>
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              <div>Subjects</div>
              <div>
                <b>
                  {guidanceAndSupportSubjects?.length < 10
                    ? `0${guidanceAndSupportSubjects?.length}`
                    : guidanceAndSupportSubjects?.length}
                </b>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              <div>Total Credits</div>
              <div>
                <b>
                  {guidanceHoursNeeded < 10
                    ? guidanceHoursNeeded === null
                      ? 0
                      : `0${guidanceHoursNeeded}`
                    : guidanceHoursNeeded}
                </b>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                gap: "10px",
                justifyContent: "space-between",
                paddingBottom: "0.5rem",
                borderBottom: "1px solid #6e6e6e",
              }}
            >
              <div>Price (£) per hourly session</div>
              <div>
                <b>
                  {
                    guidancePlan?.pricing?.find(
                      (f) => f?.level === guidanceObjective?.level
                    )?.price
                  } GBP
                </b>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                gap: "10px",
                justifyContent: "space-between",
                padding: "0.5rem 0rem",
                borderBottom: "1px solid #6e6e6e",
                fontWeight: "bolder",
              }}
            >
              <div>Total Price (£)</div>
              <div>{guidancePrice} GBP</div>
            </div>
          </div>
        </>
      )}
    </Form>
  );
}
