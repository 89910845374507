import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  Container,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

export function RevPageNoThree({
  setRegisterModalOpen,
  setPageNo,
  pageNo,
  setActivePage,
  fullFormData,
  setFullFormData,
  isMobile
}) {
  useEffect(() => {
    setActivePage(5);
  }, []);

  const subjectsArray = [
    "Biology (HL)",
    "Biology (SL)",
    "Business Management (HL)",
    "Business Management (SL)",
    "Chemistry (HL)",
    "Chemistry (SL)",
    "Computer science (HL)",
    "Computer science (SL)",
    "Economics (HL)",
    "Economics (SL)",
    "Environmental systems and societies (HL)",
    "Environmental systems and societies (SL)",
    "Mathematics: Analysis and approaches (HL)",
    "Mathematics: Analysis and approaches (SL)",
    "Mathematics: Applications and interpretation (HL)",
    "Mathematics: Applications and interpretation (SL)",
    "Physics (HL)",
    "Physics (SL)",
    "Psychology (HL)",
    "Psychology (SL)"
  ];

  const [selectedSubjects, setSelectedSubjects] = useState(fullFormData?.seekingTutoringFor || []);
  const [otherChecked, setOtherChecked] = useState(false);
  const [otherText, setOtherText] = useState("");

  const handleSelectChange = (event) => {
    const value = event.target.value;
    if (!selectedSubjects.includes(value)) {
      setSelectedSubjects([...selectedSubjects, value]);
    }
  };

  const handleDelete = (subjectToDelete) => {
    setSelectedSubjects((subjects) =>
      subjects.filter((subject) => subject !== subjectToDelete)
    );
  };

  const handleOptionChange = (event) => {
    const { checked } = event.target;
    setOtherChecked(checked);
    if (!checked) setOtherText("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // let updatedSubjects = [...selectedSubjects];
    // if (otherChecked && otherText) {
    //   updatedSubjects = [...updatedSubjects, otherText];
    // }

    if (selectedSubjects.length !== 0) {
      setFullFormData({
        ...fullFormData,
        seekingTutoringFor: selectedSubjects,
      });
      setPageNo(pageNo + 1);
    } else {
      alert("Select at least one subject");
    }
  };

  return (
    <div>
      <Typography variant="h6" sx={{fontSize: isMobile ? "1.1rem" : "1.3rem"}}>
        What subject(s) are you seeking tutoring for? *
      </Typography>
      <div >
        {otherChecked ? (
          <div style={{display: 'flex', alignItems: 'baseline', gap: '10px'}}>
          <TextField
            fullWidth
            value={otherText}
            onChange={(e) => {
              setOtherText(e.target.value)
            
            }}
            label="Enter other subject name"
            sx={{ mt: 2 }}
          />
          <Button
          disabled={otherText===""}
          variant="outlined"
          style={{height: '50px'}}
          onClick={() => {
            setSelectedSubjects(prev => [...prev, otherText])
            setOtherText("")
          }}
          >
            Add
          </Button>
          </div>
        ) : (
          <>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Select Subject</InputLabel>
              <Select
                label="Select Subject"
                onChange={handleSelectChange}
                inputProps={{ 'aria-label': 'Select Subject' }}
              >
                {subjectsArray.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={otherChecked}
                onChange={handleOptionChange}
                value="Other"
              />
            }
            label="Other"
          />
        </FormGroup>

        <Box mt={2}>
          {selectedSubjects.map((item) => (
            <Chip
              key={item}
              label={item}
              onDelete={() => handleDelete(item)}
              color="primary"
              style={{ margin: '5px', background: '#3e3e3e' }}
            />
          ))}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            mt: 4,
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="outlined"
            sx={{ fontSize: '1rem' }}
            type="button"
            onClick={() => {
              setPageNo(pageNo - 1);
            }}
          >
            Previous
          </Button>
          <Button
            disabled={selectedSubjects?.length === 0}
            variant="contained"
            sx={{ background: "#14549f", padding: '5px 20px', fontSize: '1rem' }}
            onClick={handleSubmit}
          >
            Next
          </Button>
        </Box>
      </div>
    </div>
  );
}
