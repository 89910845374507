import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./MonthComponent.css"; 

import calendarImage from "../../assets/image33.png";
import clockImage from "../../assets/image34.png";
import booksImage from "../../assets/image6.png";
import peopleImage from "../../assets/image134.png";
import tickCross from "../../assets/tickcross.png";

import Modal from "@mui/material/Modal";
import RevisionCoursesForm from "../../Components/RevisionCoursesForm/RevisionCoursesForm";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "90%",
  overflow: "scroll",
  maxWidth: "1000px",
};

const MonthComponent = ({
  summerRef,
  rapidRef,
  winterRef,
  springRef
}) => {
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [currency, setCurrency] = useState('£');
  const [exchangeRates, setExchangeRates] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch exchange rates when the component mounts
    const fetchExchangeRates = async () => {
      try {
        const response = await axios.get('https://api.exchangerate-api.com/v4/latest/GBP');
        setExchangeRates(response.data.rates);
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };
    fetchExchangeRates();
  }, []);

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  function convertPrice(price, symbol) {
    if (!exchangeRates) return price; // Return original price if exchange rates are not fetched yet

    let rate = 1; // Default rate for GBP
    switch (symbol) {
      case '£':
        rate = exchangeRates.GBP;
        break;
      case '$':
        rate = exchangeRates.USD;
        break;
      case '€':
        rate = exchangeRates.EUR;
        break;
      default:
        rate = exchangeRates.GBP;
    }

    return (price * rate).toFixed(2); // Convert price and fix to 2 decimal points
  }

  function ItemRenderer({ item, img, last }) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          borderBottom: last ? "none" : "2px solid rgb(14, 56, 136)",
          paddingBottom: "5px",
        }}
      >
        <div style={{ flex: 0.5 }}>
          <img
            src={img}
            alt="Calendar"
            style={{
              height: "2.5rem",
              marginLeft: "20px",
              objectFit: "contain",
            }}
          />
        </div>
        <div
          style={{
            flex: 1,
            textAlign: "left",
            marginLeft: "10px",
            fontWeight: !last ? "normal" : "bolder",
            fontSize: last ? "1.1rem" : "1rem",
            color: "#1e1e1e",
          }}
        >
          {item}
        </div>
      </div>
    );
  }

  const courses = {
    summerMidIb: {
      title: "Summer Course",
      price: 410,
      symbol: "£",
      duration: "01 - Month",
      hours: "16 Teaching Hours",
      students: "Max 7 students per class",
      subjects: "All Subjects (HL/SL)",
      sessionInfo: "Session Starts every week during June, July, and August.",
    },
    rapidRev: {
      title: "Rapid Revision Course",
      price: 410,
      symbol: "£",
      duration: "01 - Month",
      hours: "16 Teaching Hours",
      students: "Max 7 students per class",
      subjects: "All Subjects (HL/SL)",
      sessionInfo: "Session Starts every week during September and October.",
    },
    winterBoot: {
      title: "Winter Bootcamp",
      price: 330,
      symbol: "£",
      duration: "02 - Weeks",
      hours: "12 Teaching Hours",
      students: "Max 5 students per class",
      subjects: "All Subjects (HL/SL)",
      sessionInfo: "Session Starts every week during December and January.",
    },
    winterBootSecond: {
      title: "Spring Short Course & Paper Practice Session",
      price: 330,
      symbol: "£",
      duration: "02 - Weeks",
      hours: "12 Teaching Hours",
      students: "Max 5 students per class",
      subjects: "All Subjects (HL/SL)",
      sessionInfo:
        "Session Starts every week during February, March, and April.",
    },
  };

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {
          exchangeRates &&
        <FormControl  style={{minWidth: '300px'}}>
          <InputLabel id="demo-simple-select-label">Choose currency</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currency}
            label="Choose currency"
            onChange={handleChange}
          >
            <MenuItem value={"£"}>GBP (£)</MenuItem>
            <MenuItem value={"$"}>USD ($)</MenuItem>
            <MenuItem value={"€"}>EUR (€)</MenuItem>
          </Select>
        </FormControl>
        }
      </div>

      <div className="month-container">
        {Object.values(courses).map((item, index) => (
          <div className="categoryCol" id={item.title} style={{
            textAlign: "center",
            padding: "1rem",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            display: "flex",
            borderRadius: "2rem",
            border: "3px solid rgb(192,0,0)",
            color: "#1e1e1e",
            maxWidth: "350px",
            minWidth: "300px",
          }} key={index}
          
          ref={
            index===0 ? summerRef : index===1 ? rapidRef : index===2 ? winterRef : springRef
          }
          
          >
              <div>
                <h3
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.4rem",
                    marginTop: "20px",
                    color: "rgb(14, 56, 136)",
                  }}
                >
                  {item.title}
                </h3>

                <div
                  style={{
                    fontWeight: "bolder",
                    margin: "20px auto",
                    fontSize: "1.1rem",
                  }}
                >
                  {currency} {convertPrice(item.price, currency)} per subject
                </div>
                <div style={{ textDecoration: "underline" }}>Course Features</div>
                <ItemRenderer img={calendarImage} item={item.duration} />
                <ItemRenderer img={clockImage} item={item.hours} />
                <ItemRenderer img={peopleImage} item={item.students} />
                <ItemRenderer img={booksImage} item={item.subjects} />
                <ItemRenderer img={tickCross} item={"Planned Course Coverage"} />

                <div style={{ margin: "20px auto", maxWidth: "250px" }}>
                  {item.sessionInfo}
                </div>

                <div style={{ margin: "20px auto", maxWidth: "250px" }}>
                  Keep an eye on our <b>Upcoming Courses</b> for updates!
                </div>

                <button
                  className="revisionPageButton"
                  onClick={() => {
                    navigate(`/revision-courses-form#${item?.title}`);
                  }}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  Click Here to Register
                </button>
              </div>
          </div>
        ))}

        <Modal
          open={registerModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <RevisionCoursesForm setRegisterModalOpen={setRegisterModalOpen} />
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default MonthComponent;
