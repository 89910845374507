import React, { useState, useContext } from 'react';
import { MyContext } from '../../../Context/MyContext';

export default function QueryFormSixthStep(props) {
    const { selectedCallBack, selectedProgram } = props;
    const [lessonFrequency, setLessonFrequency] = useState('');
    const { studentForm, setStudentForm } = useContext(MyContext);

    const handleButtonClick = (frequency) => {
        // Save the clicked frequency to state
        setLessonFrequency(frequency);
        setStudentForm((prevStudentForm) => ({
            ...prevStudentForm,
            howOftenLessons: frequency,
            }))

        // Continue with your existing logic
        selectedCallBack(selectedProgram, 7);
    };

    // Save all the state information into a variable
    const allData = {
        lessonFrequency
    };

    console.log("All Data:", allData);

    return (
        <div>
            <div className='formContent'>
                <div className='sideSectionMargin mt-5'>
                    <div className="homeParaFont mt-4 text-center text-dark">
                        <b>
                            Awesome! <br />
                            And how often would like to have lessons?
                        </b>
                    </div>

                    <div className="d-grid gap-2 mt-4">
                        <button
                            className="btn btn-outline-info"
                            onClick={() => handleButtonClick("Once a week")}
                        >
                            Once a week <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => handleButtonClick("Twice a week")}
                        >
                            Twice a week <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => handleButtonClick("Once every two weeks")}
                        >
                            Once every two weeks <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => handleButtonClick("Once a month")}
                        >
                            Once a month <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                        <button
                            className="btn btn-outline-info"
                            onClick={() => handleButtonClick("Irregularly")}
                        >
                            Irregularly <i className="fa-solid fa-right-long ps-2"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
}
