import React, { useState, useEffect } from "react";
import Logo from "../../images/IBI/IBILogoWhite.png";
import "./styles.css";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useNavigate } from "react-router-dom";
import Facebook from "../../assets/facebook.png";
import Instagram from "../../assets/instagram.png";
import LinkedIn from "../../assets/linkedin.png";
import whatsappBizz from "../../assets/whatsappBizz.png";
import CallIcon from "@mui/icons-material/Call";
import { Button } from "@mui/material";

const Footer = () => {
  const navigtePages = useNavigate();

  const noPointer = { cursor: "pointer" };

  const handlePageChange = (page) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigtePages(page);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 990) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="footerContainer">
      <div className="footer">
        <div className="grid-item">
          <img
            src={Logo}
            alt="logo-error"
            style={{ maxWidth: "150px", marginBottom: "10px" }}
          />
          <p className="description">
            Demystifying the boundaries of IB education and empowering students
            to achieve excellent grades, IB INNOVATORS is an online education
            platform where you can find the best IB tutors
          </p>
        </div>
        <div className="grid-item">
          <p className="heading" style={{ color: "#ccc" }}>
            About Us
          </p>
          <p
            onClick={() => handlePageChange("whyIBI")}
            className="cursorPointer"
          >
            About IBI
          </p>
          <p
            onClick={() => handlePageChange("becomeTutor")}
            className="cursorPointer"
          >
            Become a Tutor
          </p>
          <p
            onClick={() => handlePageChange("howitworks")}
            className="cursorPointer"
          >
            How it works
          </p>
          <p
            onClick={() => handlePageChange("IBSchoolSupport")}
            className="cursorPointer"
          >
            Support for IB Schools
          </p>
        </div>
        <div className="grid-item">
          <p className="heading" style={{ color: "#ccc" }}>
            Address
          </p>
          <p className="description">
            Suite 4258, Unit 3A, 34-35 Hatton Garden, <br />
            Holborn, London, EC1N 8DX
          </p>
        </div>
        <div
          className="grid-item"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                marginBottom: "1rem",
              }}
            >
              <Button
                onClick={() => handlePageChange("register")}
                sx={{ backgroundColor: "#14549f", fontSize: "1.1rem" }}
                variant="contained"
              >
                Register Now
              </Button>
              <Button
                onClick={() => handlePageChange("login")}
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                }}
              >
                Log In
              </Button>
            </div>

            <p
              className="heading"
              style={{ color: "#ccc", marginTop: "15px", marginBottom: "5px" }}
            >
              Follow Us
            </p>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <a
                style={{ textDecoration: "none", color: "white" }}
                target="_blank"
                href="https://api.whatsapp.com/send/?phone=%2B4407462316382&text&type=phone_number&app_absent=0"
              >
                <img
                  src={whatsappBizz}
                  alt="Whatsapp Business"
                  height={"40px"}
                  style={{ borderRadius: "50%" }}
                />
              </a>

              <a
                style={{ textDecoration: "none", color: "white" }}
                target="_blank"
                href="https://www.facebook.com/Ibinnovators"
              >
                <img
                  src={Facebook}
                  alt="Facebook"
                  height={"40px"}
                  style={{ borderRadius: "50%" }}
                />
              </a>
              <a
                style={{ textDecoration: "none", color: "white" }}
                target="_blank"
                href="https://www.instagram.com/ibinnovators/"
              >
                <img
                  src={Instagram}
                  alt="Instagram"
                  height={"40px"}
                  style={{ borderRadius: "50%" }}
                />
              </a>
              <a
                style={{ textDecoration: "none", color: "white" }}
                target="_blank"
                href="https://www.linkedin.com/company/ib-innovators/"
              >
                <img
                  src={LinkedIn}
                  alt="LinkedIn"
                  height={"40px"}
                  style={{ borderRadius: "50%" }}
                />
              </a>
            </div>
          </div>

          {!isMobile && (
            <div>
              © 2024 IB INNOVATORS LTD
              <br />
              ALL RIGHTS RESERVED.
              <br />
              NOT AFFILIATED WITH IBO
            </div>
          )}
        </div>
        <div
          className="grid-item"
          style={{ display: "flex", flexDirection: "column", gap: "30px" }}
        >
          <div>
            <p className="heading" style={{ color: "#ccc" }}>
              Policies and Guidelines
            </p>
            <p className="cursorPointer" onClick={() => navigtePages("/terms-and-conditions")}>
                Terms and Condition
            </p>
            <p className="cursorPointer" onClick={() => navigtePages("/disclaimer")}>
                Disclaimer
            </p>
            <p className="cursorPointer" onClick={() => navigtePages("/privacy-policy")}>
                Privacy Policy
            </p>
          </div>

          <div
            style={{
              color: "#ccc",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => navigtePages("/company-details")}
          >
            Company Details
          </div>
        </div>
        <div className="grid-item">
          <p className="heading" style={{ color: "#ccc" }}>
            Contact Us
          </p>

          <div style={{ fontWeight: "bold", marginBottom: "5px" }}>Support</div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <CallIcon />
            <div>0207-9657567</div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <MailOutlineIcon />
            <div>support@ibinnovators.com</div>
          </div>

          <div
            style={{
              fontWeight: "bold",
              marginBottom: "5px",
              marginTop: "15px",
            }}
          >
            For Tuition
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img
              src={whatsappBizz}
              alt=""
              height={"20px"}
              style={{ borderRadius: "50%" }}
            />
            <div>+44 (0)746 231 6382</div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <MailOutlineIcon />
            <div>tuitions@ibinnovators.com</div>
          </div>

          <div
            style={{
              fontWeight: "bold",
              marginBottom: "5px",
              marginTop: "15px",
            }}
          >
            For Revision Courses
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img
              src={whatsappBizz}
              alt=""
              height={"20px"}
              style={{ borderRadius: "50%" }}
            />
            <div>+44 (0)746 231 6382</div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <MailOutlineIcon />
            <div>courses@ibinnovators.com</div>
          </div>

          {isMobile && (
            <div style={{ marginTop: "50px", flex: 1, textAlign: "center" }}>
              © 2024 IB INNOVATORS LTD
              <br />
              ALL RIGHTS RESERVED.
              <br />
              NOT AFFILIATED WITH IBO
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
