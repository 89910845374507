import React from 'react'

export default function Accordion(props) {
    const { accordionInfo } = props
    return (
        <div className="accordion" id="accordionExample">
            {accordionInfo.map((accordionItem, i) => {
                return (
                    <React.Fragment key={i}>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id={`heading${i}`}>
                                <button style={{color: 'white', background: 'linear-gradient(109.6deg, rgb(61, 121, 176) 11.3%, rgb(35, 66, 164) 91.1%)'}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${i}`} aria-expanded="false" aria-controls={`collapse${i}`}>
                                    {accordionItem.title}
                                </button>
                            </h2>
                            <div id={`collapse${i}`} className="accordion-collapse collapse" aria-labelledby={`heading${i}`} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {accordionItem.content}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            })}


        </div>
    )
}
