import React, { useEffect, useState } from "react";
import "./index.css";

import {
  collection,
  addDoc,
  getDocs,
  where,
  query,
  snapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";

import First from '../../assets/image114.png';
import Second from '../../assets/image111.png';
import Third from '../../assets/image130.png';


import Fourth from '../../assets/image132.png';
import Fifth from '../../assets/image187.png';

export default function UnderstandIB({tutorsRef, studentsRef}) {
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
    const navigate=useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      const userListCollection = collection(db, "userList");
      const snapshot = await getDocs(userListCollection);

      const fetchedStudents = [];
      const fetchedTeachers = [];

      snapshot.forEach((doc) => {
        const data = doc.data();
        if (data.type === "student" || data.type === "parent") {
          fetchedStudents.push(data);
        } else if (data.type === "teacher") {
          fetchedTeachers.push(data);
        }
      });

      setStudents(fetchedStudents);
      setTeachers(fetchedTeachers);
    };

    fetchData();
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 600); // You can adjust the width threshold as needed
  };

  // Listen for window resize events to update isMobile state
  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const cardStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "0 auto",
    flexDirection: "row",
  };

  const counterContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "wrap",
    flex: 1,
    flexDirection: isMobile ? "column" : "row",
  };

  const counterStyle = {
    background: "rgb(14, 56, 136)",
    color: "white",
    padding: "0.5rem 1.5rem",
    borderRadius: "10px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    fontSize: "2rem",
    textAlign: "center",
    flex: 1,
  };

  const counterStyleSecond = {
    color: "#1e1e1e",
    padding: "0.5rem 1.5rem",
    borderRadius: "10px",
    fontSize: "3.5rem",
    textAlign: "center",
    flex: 1,
    fontWeight: 'bold'
  };

  const [counter1, setCounter1] = useState(0);
  const [counter2, setCounter2] = useState(0);
  const [counter3, setCounter3] = useState(0);
  const [counter4, setCounter4] = useState(0);
  const [counter5, setCounter5] = useState(0);

  const targetValue1 = 2100; // Specify your desired target value
  const targetValue2 = 710; // Specify your desired target value
  const targetValue3 = 160; // Specify your desired target value
  const targetValue4 = 8600; // Specify your desired target value
  const targetValue5 = 950; // Specify your desired target value
  const animationDuration = 10000; // Duration in milliseconds

  useEffect(() => {
    const increment1 = targetValue1 / animationDuration;
    const increment2 = targetValue2 / animationDuration;
    const increment3 = targetValue3 / animationDuration;
    const increment4 = targetValue4 / animationDuration;
    const increment5 = targetValue5 / animationDuration;

    let startTime = null;

    function updateCounter(timestamp) {
      if (!startTime) startTime = timestamp;
      const elapsedTime = timestamp - startTime;
      if (elapsedTime < animationDuration) {
        setCounter1(Math.min(targetValue1, increment1 * elapsedTime));
        setCounter2(Math.min(targetValue2, increment2 * elapsedTime));
        setCounter3(Math.min(targetValue3, increment3 * elapsedTime));
        setCounter4(Math.min(targetValue4, increment4 * elapsedTime));
        setCounter5(Math.min(targetValue5, increment5 * elapsedTime));
        requestAnimationFrame(updateCounter);
      } else {
        setCounter1(targetValue1);
        setCounter2(targetValue2);
        setCounter3(targetValue3);
        setCounter4(targetValue4);
        setCounter5(targetValue5);
      }
    }

    requestAnimationFrame(updateCounter);

    // Cleanup function
    return () => {
      setCounter1(0);
      setCounter2(0);
      setCounter3(0);
      setCounter4(0);
      setCounter5(0);
    };
  }, [targetValue1, targetValue2, targetValue3, targetValue4, targetValue5, animationDuration]);



  function handleButtonClick(title){

    if(title==="Top tutors"){tutorsRef.current.scrollIntoView({ behavior: 'smooth' })}

    else if(title==="Real IB grades"){studentsRef.current.scrollIntoView({ behavior: 'smooth' })}

    else if(title==="Perfect match"){navigate("/howitworks")}

    else if(title==="Proven methodology"){navigate("/whyIBI")}

    else if(title==="Personalized support"){window.open("https://wa.me/+447706670084", "_blank");}

    else if(title==="Outstanding reputation"){studentsRef.current.scrollIntoView({ behavior: 'smooth' })}

  }

  return (
    <div
      style={{
        borderRadius: "5px",
        color: "rgb(14, 56, 136)",
        marginTop: "5rem",
      }}
      id="understandIB"
    >



      <div style={cardStyle}>
        <div style={counterContainerStyle}>


          <div style={{ flex: 1, width: "100%"}}>

          <div style={{flex: 1, width: "100%", textAlign: 'center'}}>
                 {/* <i
                    style={{ color: "#1e1e1e", fontSize: "60px" }}
                    className="fa fa-graduation-cap"
                    
                  ></i> */}
                  <img src={First} alt="Students" style={{height: '130px'}} />
                  </div>

            <div style={counterStyleSecond}>
              <strong>{Math.round(counter1)}+</strong>
            </div>
            
            <div
              style={{
                marginTop: "0px",
                marginRight: "10px",
                marginLeft: "10px",
                minHeight: '150px',
                textAlign: 'center',
                color: '#1e1e1e',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                alignItems: 'baseline'
              }}
            >
                
                  <div>
              Students Enrolled Globally
              </div>
            </div>
          </div>

          <div style={{ flex: 1, width: "100%", textAlign: 'center' }}>
          {/* <i
                    style={{ color: "#1e1e1e", fontSize: "60px" }}
                    className="fa fa-person-chalkboard"
                    
                  ></i> */}
                 <div style={{flex: 1, width: "100%", textAlign: 'center'}}>
                 {/* <i
                    style={{ color: "#1e1e1e", fontSize: "60px" }}
                    className="fa fa-graduation-cap"
                    
                  ></i> */}
                  <img src={Second} alt="Students" style={{height: '130px'}} />
                  </div>
            <div style={counterStyleSecond}>
              <strong>{Math.round(counter2)}+</strong>
            </div>
            <div
              style={{
                marginTop: "0px",
                marginRight: "10px",
                marginLeft: "10px",
                minHeight: '150px',
                textAlign: 'center',
                color: '#1e1e1e',
                fontSize: '1.5rem',
                fontWeight: 'bold'
              }}
            >
                  <div >
              Certified IB Tutors
              </div>
            </div>
          </div>

          <div style={{ flex: 1, width: "100%", textAlign: 'center' }}>
          {/* <i
                    style={{ color: "#1e1e1e", fontSize: "60px" }}
                    className="fa-solid fa-restroom"
                    
                  ></i> */}
                   <div style={{flex: 1, width: "100%", textAlign: 'center'}}>
                 {/* <i
                    style={{ color: "#1e1e1e", fontSize: "60px" }}
                    className="fa fa-graduation-cap"
                    
                  ></i> */}
                  <img src={Third} alt="Students" style={{height: '130px'}}/>
                  </div>
            <div style={counterStyleSecond}>
              <strong>{Math.round(counter3)}+</strong>
            </div>
            <div
              style={{
                marginTop: "0px",
                marginRight: "10px",
                marginLeft: "10px",
                minHeight: '150px',
                textAlign: 'center',
                color: '#1e1e1e',
                fontSize: '1.5rem',
                fontWeight: 'bold'
              }}
            >
                  <div>
              Seasoned IB Examiners
              </div>
            </div>
          </div>


          <div style={{ flex: 1, width: "100%", textAlign: 'center' }}>
          {/* <i
                    style={{ color: "#1e1e1e", fontSize: "60px" }}
                    className="fa-solid fa-restroom"
                    
                  ></i> */}
                   <div style={{flex: 1, width: "100%", textAlign: 'center'}}>
                 {/* <i
                    style={{ color: "#1e1e1e", fontSize: "60px" }}
                    className="fa fa-graduation-cap"
                    
                  ></i> */}
                  <img src={Fourth} alt="Students" style={{height: '130px'}}/>
                  </div>
            <div style={counterStyleSecond}>
              <strong>{Math.round(counter4)}+</strong>
            </div>
            <div
              style={{
                marginTop: "0px",
                marginRight: "10px",
                marginLeft: "10px",
                minHeight: '150px',
                textAlign: 'center',
                color: '#1e1e1e',
                fontSize: '1.5rem',
                fontWeight: 'bold'
              }}
            >
                  <div>
              Ongoing Lessons
              </div>
            </div>
          </div>

          <div style={{ flex: 1, width: "100%", textAlign: 'center' }}>
          {/* <i
                    style={{ color: "#1e1e1e", fontSize: "60px" }}
                    className="fa-solid fa-restroom"
                    
                  ></i> */}
                   <div style={{flex: 1, width: "100%", textAlign: 'center'}}>
                 {/* <i
                    style={{ color: "#1e1e1e", fontSize: "60px" }}
                    className="fa fa-graduation-cap"
                    
                  ></i> */}
                  <img src={Fifth} alt="Students" style={{height: '130px'}}/>
                  </div>
            <div style={counterStyleSecond}>
              <strong>{Math.round(counter5)}+</strong>
            </div>
            <div
              style={{
                marginTop: "0px",
                marginRight: "10px",
                marginLeft: "10px",
                minHeight: '150px',
                textAlign: 'center',
                color: '#1e1e1e',
                fontSize: '1.5rem',
                fontWeight: 'bold'
              }}
            >
                  <div>
              Ongoing IB Courses
              </div>
            </div>
          </div>

        </div>
      </div>




      <div
        className="homePageHeadings text-center textTheme"
      >
        <div style={{ fontSize: isMobile ? "2rem" : "2.5rem", color: "rgb(14, 56, 136)", fontWeight: 'bold' }}>
          Thousands of students <u style={{cursor: 'pointer'}} onClick={() => {studentsRef.current.scrollIntoView({ behavior: 'smooth' })}}>Trust us</u>!
        </div>
      </div>

      <p style={{ textAlign: "center", fontSize: '1.4rem' }} className="p-3">
        Seeing you succeed, reach your goals and head toward a bright academic
        future are our priorities. It’s our job, and passion, to give you the
        best online tutoring experience possible. And that’s why students and
        parents around the world trust <b >IB <span style={{color: 'rgb(156, 16, 16)'}}>INNOVATORS</span></b>.
      </p>
      <div className="row mt-5">
        {[
          {
            icon: "fa fa-person-chalkboard",
            title: "Top tutors",
            description:
              "Our tutors are hand-picked from the best universities in the world.",
          },
          {
            icon: "fa fa-graduation-cap",
            title: "Real IB grades",
            description:
              "Our tutors have real-life IB, IGCSE and university experience.",
          },
          {
            icon: "fa-solid fa-check",
            title: "Perfect match",
            description:
              "We pair you with the best tutor for your unique needs.",
          },
          {
            icon: "fa-solid fa-brain",
            title: "Proven methodology",
            description:
              "Our approach to tutoring has been successful for nearly 8 years.",
          },
          {
            icon: "fa-regular fa-comments",
            title: "Personalized support",
            description:
              "We are with you every step of the way – from selecting subjects to exams and applying to university.",
          },
          {
            icon: "fa-solid fa-restroom",
            title: "Outstanding reputation",
            description:
              "Hundreds of schools are partnering with us after having recognized how IBI improves students’ results.",
          },
        ].map((card) => (
          <div className="col-md-4" key={card.title}>
            <div className="flip-card">
              <div className="flip-card-front">
                <div className="card-body text-center">
                  <i
                    style={{ color: "rgb(156, 16, 16)", fontSize: "85px" }}
                    className={card.icon}
                  ></i>
                  <div
                    style={{ fontSize: "1.85rem" }}
                    className="card-title mt-3 font-weight-bold homePageSubHeadings"
                  >
                    {card.title}
                  </div>
                </div>
              </div>
              <div className="flip-card-back">
                <div className="card-body text-center">
                  <p className="p-3" style={{fontSize: '1.2rem'}}>{card.description}</p>

                    <button  onClick={() => handleButtonClick(card.title)} style={{backgroundColor: 'transparent',
                border: '1px solid white',
                padding: '0.3rem 1rem'}}>Learn More...</button>
                  </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
