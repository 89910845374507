import React, { useState, useEffect, useContext } from "react";
import "./ContactUs.css";
import StudentImage from "../../assets/contactUs.png";

import StudentImageMobile from "../../assets/contactUsMobile.png";
import Students from "../../assets/contactUsBottom.jpg";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";
import { db } from "../../firebase";
import { MyContext } from "../../Context/MyContext";
import { collection, addDoc, getDocs, where, query, doc, updateDoc } from "firebase/firestore";
import { Helmet } from "react-helmet";

import { MuiTelInput } from 'mui-tel-input'
import { Autocomplete, Box, InputLabel, MenuItem, Select, TextField,FormControl, Button } from "@mui/material";
import { toast } from "react-hot-toast";

export default function ContactUs() {


  
  const { seoData, adminAddNotification, countries } =
    useContext(MyContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState(null)
  
  const [type, setType] = useState("");
  const [howCanWeSupport, setHowCanWeSupport] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [graduationYear, setGraduationYear] = useState("");
  const [thisPageSEO, setThisPageSEO] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const graduationYears=[
    "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031"
  ]

  const handleChange = (newPhone) => {
    setPhone(newPhone)
  }

  useEffect(() => {
    if(seoData){
      setThisPageSEO(seoData?.find((e) => e?.pageName === "Contact Us"))
    }
  }, [seoData])

  async function formSubmit(e) {
    e.preventDefault();

    if(
      (firstName && firstName !== "") &&
      (lastName && lastName !== "") &&
      (phone && phone !== "") &&
      (email && email !== "") &&
      country &&
      (graduationYear && graduationYear !== "") &&
      (howCanWeSupport && howCanWeSupport !== "")
    ){
      setSubmitting(true)
    const body = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      email: email,
      country: country,
      graduationYear: graduationYear,
      howCanWeSupport: howCanWeSupport,
      submittedAt: new Date
    };

    // Save the form data to Firestore (your existing code)

    const tableRows = Object.entries(body)
      .map(
        ([key, value]) => `
        <tr>
          <td>${key}</td>
          <td>${value}</td>
        </tr>
      `
      )
      .join("");

    const emailParams = {
      from_name: "IBInnovators",
      to_name: "IBInnovators",
      send_to: "contact@ibinnovators.com", 
      subject: "New Contact Us Form Submission",
      message: `
      <html>
      <head>
      </head>
      <body>
      <p>A person just submitted Contact Us Form:</p>
      <table style="border: 1px solid #000; border-collapse: collapse; width: 100%;">
      <tbody>
          <tr>
              <td style="border: 1px solid #000; padding: 8px;"><strong>First Name:</strong></td>
              <td style="border: 1px solid #000; padding: 8px;"><strong>Last Name:</strong></td>
              <td style="border: 1px solid #000; padding: 8px;"><strong>Phone:</strong></td>
              <td style="border: 1px solid #000; padding: 8px;"><strong>Email:</strong></td>
              <td style="border: 1px solid #000; padding: 8px;"><strong>Country:</strong></td>
              <td style="border: 1px solid #000; padding: 8px;"><strong>Graduation Year:</strong></td>
              <td style="border: 1px solid #000; padding: 8px;"><strong>How Can We Support:</strong></td>
          </tr>
          <tr>
              <td style="border: 1px solid #000; padding: 8px;">${body.firstName}</td>
              <td style="border: 1px solid #000; padding: 8px;">${body.lastName}</td>
              <td style="border: 1px solid #000; padding: 8px;">${body.phone}</td>
              <td style="border: 1px solid #000; padding: 8px;">${body.email}</td>
              <td style="border: 1px solid #000; padding: 8px;">${body.country?.label}</td>
              <td style="border: 1px solid #000; padding: 8px;">${body.graduationYear}</td>
              <td style="border: 1px solid #000; padding: 8px;">${body.howCanWeSupport}</td>
          </tr>
      </tbody>
  </table>
  
      <p>Ibinnovators</p>
      
      </body>
      </html>
      
  `,
    };

    // Use your email service credentials here
    const serviceId = process.env.REACT_APP_EMAILSERVICEID;
    const templateId = process.env.REACT_APP_EMAILTEMPLATEID;
    const userId = process.env.REACT_APP_EMAILUSERID;

    
    const ordersRef = collection(db, 'adminPanel');
    const customDocRef = doc(ordersRef, 'contactUsForm');
    const orderCollectionRef = collection(customDocRef, 'contactUsForm');

    try{
      const docRef = await addDoc(orderCollectionRef, body);
      const id = docRef.id;

      // Update the document to include the ID
      await updateDoc(docRef, { id: id });
      await adminAddNotification(`Contact Us Form was just submitted by ${body.email}`)

    await emailjs
      .send(serviceId, templateId, emailParams, userId)
      .then((response) => {
        console.log("Email Sent Successfully")
      })
      .catch((error) => {
        console.error("Error sending email");
      });
        // Handle success, such as resetting form fields
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setCountry(null);
        setGraduationYear("");
        setHowCanWeSupport("");
        toast.success("Form Submitted")
    } catch(error){toast.error("Error Occured")} finally{
      setSubmitting(false)
    }
  } else {
    toast("Please fill all details")
  }
  }

  const [isMobile, setIsMobile] = useState(false);

  const [isRealMobile, setIsRealMobile] = useState(false)

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 1200); // You can adjust the width threshold as needed
    setIsRealMobile(window.innerWidth <= 800)
  };

  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const navigate = useNavigate();


  
  const createHelmetTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const headTags = Array.from(doc.head.children);
  
    return headTags.map((tag, index) => {
      const tagName = tag.tagName.toLowerCase();
  
      if (tagName === 'title') {
        return <title key={index}>{tag.innerText}</title>;
      } else if (tagName === 'meta') {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <meta key={index} {...attributes} />;
      } else if (tagName === 'link') {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <link key={index} {...attributes} />;
      } else if (tagName === 'script' && tag.type === 'application/ld+json') {
        return <script key={index} type="application/ld+json">{tag.innerText}</script>;
      } else {
        return null;
      }
    });
  };

  return (
    <>
    
<Helmet>
      {thisPageSEO?.schema && createHelmetTags(thisPageSEO.schema)}
    </Helmet>

    
<div style={{flex: 1, width: '100%', background: 'red', height: imageLoaded ? "max-content" : '600px', background: '#14549f'}}>
      <div style={{ maxWidth: '1800px', flex: 1, margin: '0px auto' }}>
        {isRealMobile ? (
          <img
            src={StudentImageMobile}
            width="100%"
            alt=""
            onLoad={() => setImageLoaded(true)}
            height="auto"
            style={{maxWidth: '1800px'}}
          />
        ) : (
          <img src={StudentImage} onLoad={() => setImageLoaded(true)} width="100%" alt="" height="auto" 
          />
        )}
      </div>
      </div>


    {/* <div style={{ flex: 1 }}>
        {!isRealMobile ? (
          <img
          src={StudentImage} alt="Student" 
            width="100%"
            height="auto"
          />
        ) : (
          <img
            src={StudentImageMobile}
            width="100%"
            alt="Student" 
            height="auto"
          />
        )}
      </div> */}


      <div className="contact-page">
        <section className="contact-content">
          <div className="contact-form">
            <h3 style={{fontSize: isMobile ? "1.3rem" : '1.8rem'}}>We'd be delighted to receive your queries!</h3>
            <p style={{fontSize: isMobile ? "1rem" : '1.2rem'}}>
              We're always here to address your queries! Check out the links
              below for more helpful details, or reach out to us and we'll
              respond promptly.
            </p>

            <div
              className="additional"
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "0.5rem",
              }}
            >
              <h3 style={{fontSize: isMobile ? "1.3rem" : '1.8rem'}}>Eager to begin your assistance?</h3>
              <p style={{fontSize: isMobile ? "1rem" : '1.2rem'}}>
                If you're prepared to find the ideal tutor, sign up with us.
                We'll reach out in the next few days with suggestions tailored
                specifically for you.
              </p>
              <Button
              variant="contained"
                onClick={() => {
                  navigate("/register");
                }}
                style={{background: '#14549f'}}
              >
                Register Now
              </Button>
            </div>
          </div>










          <div
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
              padding: "0.5rem",
              backgroundColor: "#ddd",
              height: "100%",
              borderRadius: "1rem",
              margin: "2rem",
            }}
            className="contact-form-second"
          >
            <div
              style={{
                padding: "1rem",
                backgroundColor: "#FFF",
                borderRadius: "0.5rem",
              }}
            >
              <form onSubmit={formSubmit}>
                <h2 style={{textAlign: 'center', fontWeight: 'bold', fontSize: isMobile ? "1.5rem" : "2.5rem", margin: '1rem'}}>WE GOT YOUR EXAMS COVERED</h2>


                  <div style={{flex: 1, display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '1rem'}}>

                
                  <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: isMobile ? 'column' : 'row', gap: '10px'}}>
          <TextField
            style={{ flex: 1, minWidth: "300px" }}
            label="First Name"
            variant="outlined"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            type="text"
            id="firstName"
            name="firstName"
            required
          />
          
          <TextField
            style={{ flex: 1, minWidth: "300px" }}
            label="Last Name"
            variant="outlined"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            type="text"
            id="lastName"
            name="lastName"
            required
          />
          </div>

          <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: isMobile ? 'column' : 'row', gap: '10px'}}>


          <TextField
            style={{ flex: 1, minWidth: "300px" }}
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="email"
            id="Email"
            name="Email"
            required
          />

                <MuiTelInput required value={phone} label="Phone" 
            style={{ flex: 1, minWidth: "300px" }} onChange={handleChange} />

            
          </div>


          <Autocomplete
          id="country-select-demo"
          options={countries}
          style={{ flex: 1, minWidth: "300px" }} 
          value={country}
          onChange={(item, newValue) => setCountry(newValue)}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                alt=""
              />
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose your country"
              required
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />


<FormControl fullWidth >
  <InputLabel id="demo-simple-select-label">Graduation Year *</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={graduationYear}
    label="Select Service *"
    required
    onChange={(e) => {setGraduationYear(e.target.value)}}
  >
    {
      graduationYears?.map((item) => 
    <MenuItem key={item} value={item}>{item}</MenuItem>
      )
    }
  </Select>
</FormControl>

<TextField 
  value={howCanWeSupport}
  onChange={(e) => {
    setHowCanWeSupport(e.target.value);
  }}
  id="support"
  name="support"
  rows={3}
  multiline
  required
  label="Message"
  />

                </div>


                {/* <div style={{flex: 1}} className="form-group">
                <textarea
  value={howCanWeSupport}
  onChange={(e) => {
    setHowCanWeSupport(e.target.value);
  }}
  id="support"
  name="support"
  rows="4"
  required
  placeholder="MESSAGE"
></textarea>

                </div> */}


                <Button disabled={submitting} sx={{backgroundColor: '#14549f', width: '100%'}} variant="contained" type="submit">{submitting ? "Submitting" : "Submit"}</Button>
              </form>
            </div>
          </div>
        </section>
      </div>

      
      <img
              src={Students}
              alt=""
              style={{
                width: "100%",
                maxHeight: "30rem",
                objectFit: "contain",
                marginTop: "1rem",
                objectFit: 'contain',
                objectPosition: 'bottom',
              }}
            />
    </>
  );
}