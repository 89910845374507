import classes from "./BlogCard.module.css";
import React from "react";
import { useNavigate } from "react-router-dom";

const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).toUpperCase();
};

export default function BlogCard({image, date, title, link}){
    const navigate=useNavigate();

    const formattedDate = formatDate(date);


    return(
        <div className={classes.card} onClick={() => {
            navigate(link)
        }}>
            <div className={classes.cardImageContainer}>
                <img src={image} alt="" style={{width: '100%', height: '100%', objectFit: 'cover'}} />

            </div>

            <div className={classes.cardContent}>

            <div className={classes.cardDate}>
            {formattedDate}
            </div>
            
            <div className={classes.cardTitle}>{title}</div>

            <div className={classes.readMore}>Read More</div>
            </div>
        </div>
    )
}