import React, { useState, useEffect } from "react";
import { RevPageNoFive } from "./RevPageNoFive";
import { RevPageNoFour } from "./RevPageNoFour";
import { RevPageNoOne } from "./RevPageNoOne";
import { RevPageNoThree } from "./RevPageNoThree";
import { RevPageNoTwo } from "./RevPageNoTwo";
import { ThankYouScreen } from "./ThankYouScreen";
import RegisterImage from '../../assets/registerScreenImage.jpg'
import { PageNoSix } from "./PageNoSix";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function UpcomingCoursesForm({ setRegisterModalOpen, data }) {
  const [pageNo, setPageNo] = useState(1);
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1);

  const [fullFormData, setFullFormData] = useState({
    userDetails: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      city: "",
      state: "",
      zip: "",
      country: null,
      address: "",
      addressLineTwo: "",
      relation: "",
      parentFirstName: "",
      parentLastName: "",
      parentPhone: "",
      parentEmail: "",
    },
  })

  
  useEffect(() => {
    console.log("FULL FORM DATA", fullFormData);
  }, [fullFormData]);
  

  const [showImage, setShowImage] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setShowImage(window.innerWidth > 900);
    setIsMobile(window.innerWidth <= 600); // You can adjust the width threshold as needed
  };

  // Listen for window resize events to update isMobile state
  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (


    <>
    
    {pageNo === 6 ?
     (
        <ThankYouScreen
          setRegisterModalOpen={setRegisterModalOpen}
          setPageNo={setPageNo}
          pageNo={pageNo}
          setActivePage={setActivePage}
          fullFormData={fullFormData}
setFullFormData={setFullFormData}
isMobile={isMobile}
        />
      )
    
    
    :
    
    
    <div

    // style={{
    //   minHeight:'622px'}}
    >
    <div
      style={{
        display: "flex",
        flex: 1,
        borderBottom: "1px solid #ccc",
        paddingBottom: "1rem",
        fontWeight: "bold",
        marginBottom: "1rem",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        fontSize: isMobile ? "1.5rem" : "2rem",
      }}
    >
      <div>Upcoming Courses Form</div>
      <div>
        <Button
          variant="contained"
          color="error"
          onClick={() => navigate("/pricing")}
        >
          Close Form
        </Button>
      </div>
    </div>

{
  !isMobile && 
      <div style={{ display: "flex", flex: 1, flexWrap: 'wrap' }}>
        {[...Array(5).keys()].map((index) => (
          <div style={{ flex: 1, margin: "10px" }} key={index}>
            <div
              style={{
                position: "relative",
                paddingTop: "20px", // Adjust this value as needed for spacing
                paddingBottom: "10px",
                textAlign: 'center'
              }}
            >
              {index === 0 ? (
                <span style={{ fontSize: "18px" }}>
                  Your Course is just a few steps away
                </span>
              ) : (
                <span style={{ fontSize: "18px" }}>Step-{index}</span>
              )}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  height: "10px",
                  background:
                    activePage >= index + 1 ? "rgb(156, 16, 16)" : "#ccc",
                  backgroundImage: `linear-gradient(to right, transparent 50%, ${
                    activePage >= index + 1 ? "rgb(156, 16, 16)" : "#ccc"
                  } 50%)`,
                }}
              />
            </div>
          </div>
        ))}
      </div>

}

      
<div style={{flex: 1, display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start'}}>


{showImage && (
              <img
                src={RegisterImage}
                style={{ float: "left", width: "40%" }}
              />
            )}

  <div style={{flex: 1.5, paddingBottom: '2rem'}}>

      {pageNo === 1 && (
        <RevPageNoOne
        data={data}
          setRegisterModalOpen={setRegisterModalOpen}
          setActivePage={setActivePage}
          setPageNo={setPageNo}
          pageNo={pageNo}
          fullFormData={fullFormData}
setFullFormData={setFullFormData}
isMobile={isMobile}
        />
      )}


      {pageNo === 2 && (
        <RevPageNoThree
          setRegisterModalOpen={setRegisterModalOpen}
          setPageNo={setPageNo}
          pageNo={pageNo}
          setActivePage={setActivePage}
          fullFormData={fullFormData}
setFullFormData={setFullFormData}
isMobile={isMobile}
        />
      )}

      {pageNo === 3 && (
        <RevPageNoFour
          setRegisterModalOpen={setRegisterModalOpen}
          setPageNo={setPageNo}
          pageNo={pageNo}
          setActivePage={setActivePage}
          fullFormData={fullFormData}
setFullFormData={setFullFormData}
isMobile={isMobile}
        />
      )}

      {pageNo === 4 && (
        <RevPageNoFive
        data={data}
          setRegisterModalOpen={setRegisterModalOpen}
          setPageNo={setPageNo}
          pageNo={pageNo}
          setActivePage={setActivePage}
          fullFormData={fullFormData}
setFullFormData={setFullFormData}
isMobile={isMobile}
        />
      )}

      
{pageNo === 5 && (
        <PageNoSix
        data={data}
          setRegisterModalOpen={setRegisterModalOpen}
          setPageNo={setPageNo}
          pageNo={pageNo}
          setActivePage={setActivePage}
          fullFormData={fullFormData}
setFullFormData={setFullFormData}
isMobile={isMobile}
        />
      )}


</div>
    </div>

    
</div>

    
}
    
    
</>
  );
}

export default UpcomingCoursesForm;
