import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./RelatedBlogCard.module.css";

const formatDate = (timestamp) => {
  const date = new Date(
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
  );
  return date
    .toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    })
    .toUpperCase();
};

export default function RelatedBlogCard({ date, title, description, link }) {
  const formattedDate = formatDate(date);
    const navigate = useNavigate();
  

  return (
    <div onClick={() => {navigate(link)}} className={classes.card}>
      <div className={classes.date}>{formattedDate}</div>
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>
        {/* {description} */}
        Adobe Analytics vs GA4 comparison shows the former offers high customization and analytics, and the latter offers simple metrics with some customization.
      </div>
    </div>
  );
}
