import React, { useContext } from "react";
import "./WhyIBI.css";
import groupPeople from "./../../assets/Revision.png";
import background from "../../assets/WhyIbI.png";
import background2 from "../../assets/WhyIBIMobile.png";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import mainImage from "../../assets/mainImage.svg";
import secondImage from "../../assets/whyIbiSecond.svg";
import thirdImage from "../../assets/whyIbiThird.jpg";

import { Helmet } from "react-helmet";
import { MyContext } from "../../Context/MyContext";

export default function WhyIBI() {
  const [isMobile, setIsMobile] = useState(false);
  const {seoData} = useContext(MyContext)
  const [thisPageSEO, setThisPageSEO] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if(seoData){
      setThisPageSEO(seoData?.find((e) => e?.pageName === "Why IBI"))
    }
  }, [seoData])


  
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 600); // You can adjust the width threshold as needed
  };

  // Listen for window resize events to update isMobile state
  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const navigtePages = useNavigate();

  const handleChange = (navValue) => {
    navigtePages(navValue);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  
  const createHelmetTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const headTags = Array.from(doc.head.children);
  
    return headTags.map((tag, index) => {
      const tagName = tag.tagName.toLowerCase();
  
      if (tagName === 'title') {
        return <title key={index}>{tag.innerText}</title>;
      } else if (tagName === 'meta') {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <meta key={index} {...attributes} />;
      } else if (tagName === 'link') {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <link key={index} {...attributes} />;
      } else if (tagName === 'script' && tag.type === 'application/ld+json') {
        return <script key={index} type="application/ld+json">{tag.innerText}</script>;
      } else {
        return null;
      }
    });
  };

  return (
    <>

<Helmet>
      {thisPageSEO?.schema && createHelmetTags(thisPageSEO.schema)}
    </Helmet>

      {/* <Helmet>
        <title>{thisPageSEO?.title}</title>
        <meta name="title" content={thisPageSEO?.metaTitle} />
        <meta name="description" content={thisPageSEO?.metaDescription} />
        <link rel="canonical" href={thisPageSEO?.canonicalUrl} />
        <script type="application/ld+json">{JSON.stringify(thisPageSEO?.schema)}</script>
      </Helmet> */}


<div style={{flex: 1, width: '100%', background: 'red', height: imageLoaded ? "max-content" : '600px', background: '#14549f'}}>
      <div style={{ maxWidth: '1800px', flex: 1, margin: '0px auto' }}>
        {isMobile ? (
          <img
            src={background2}
            width="100%"
            alt=""
            onLoad={() => setImageLoaded(true)}
            height="auto"
            style={{maxWidth: '1800px'}}
          />
        ) : (
          <img src={background} onLoad={() => setImageLoaded(true)} width="100%" alt="" height="auto" 
          />
        )}
      </div>
      </div>

      {/* <div style={{ flex: 1 }}>
        {isMobile ? (
          <img src={background2} width="100%" alt="Why IBI" height="auto" />
        ) : (
          <img src={background} width="100%" alt="Why IBI" height="auto" />
        )}
      </div> */}

      <div
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          paddingTop: "5rem",
          paddingBottom: "5rem",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <div style={{ paddingRight: "10vw", paddingLeft: "10vw" }}>
          <div
            style={{
              maxWidth: "1600px",
              margin: "0 auto",
            }}
          >
            {/* First Section: Image on Right */}
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
                marginBottom: "50px",
              }}
            >
              {/* Text Content */}
              <div
                style={{
                  flex: 1,
                  paddingRight: !isMobile ? "2rem" : "0rem",
                  marginBottom: "1rem",
                }}
              >
                <div
                  style={{ fontSize: isMobile ? "2rem" : "2.5rem" }}
                  className="homePageSubHeadings mt-3"
                >
                  IB INNOVATORS - Where Education Knows No Bounds!
                </div>
                <div
                  style={{
                    color: "#1F4E79",
                    fontSize: isMobile ? "1.5rem" : "1.8rem",
                    marginTop: "1rem",
                  }}
                >
                  As a leading online tutoring platform with over 8 years of
                  unwavering commitment to student success, we stand tall as a
                  beacon of guidance and support for ambitious IBDP, IBMYP, and
                  IGCSE students.
                </div>
              </div>

              <div style={{ flex: 1.4 }}>
                <img
                  src={mainImage}
                  alt="IBI Image"
                  style={{ width: "100%", objectFit: "cover" }}
                />
              </div>
            </div>

            {/* Second Section: Image on Left */}
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
                marginBottom: "50px",
              }}
            >
              {!isMobile ? (
                <>
                  <div style={{ flex: 1.2 }}>
                    <img
                      src={secondImage}
                      alt="IBI Image"
                      style={{ width: "100%", objectFit: "cover" }}
                    />
                  </div>

                  {/* Text Content */}
                  <div
                    style={{
                      flex: 1,
                      paddingLeft: !isMobile ? "2rem" : "0rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <div
                      style={{ fontSize: isMobile ? "2rem" : "2.5rem" }}
                      className="homePageSubHeadings mt-3"
                    >
                      Our Legacy of Excellence
                    </div>
                    <div
                      style={{
                        color: "#1F4E79",
                        fontSize: isMobile ? "1.5rem" : "1.8rem",
                        marginTop: "1rem",
                      }}
                    >
                      For the past 8 years, IB Innovators has been a trailblazer
                      in educational innovation, illuminating the path for
                      countless students to surpass their potential and achieve
                      remarkable academic feats. Our legacy of excellence is not
                      confined to mere years; it reverberates in the lives we
                      have transformed and the dreams we have nurtured.
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* Text Content */}
                  <div
                    style={{
                      flex: 1,
                      paddingLeft: !isMobile ? "2rem" : "0rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <div
                      style={{ fontSize: isMobile ? "2rem" : "2.5rem" }}
                      className="homePageSubHeadings mt-3"
                    >
                      Our Legacy of Excellence
                    </div>
                    <div
                      style={{
                        color: "#1F4E79",
                        fontSize: isMobile ? "1.5rem" : "1.8rem",
                        marginTop: "1rem",
                      }}
                    >
                      For the past 8 years, IB Innovators has been a trailblazer
                      in educational innovation, illuminating the path for
                      countless students to surpass their potential and achieve
                      remarkable academic feats. Our legacy of excellence is not
                      confined to mere years; it reverberates in the lives we
                      have transformed and the dreams we have nurtured.
                    </div>
                  </div>

                  <div style={{ flex: 1.2 }}>
                    <img
                      src={secondImage}
                      alt="IBI Image"
                      style={{ width: "100%", objectFit: "cover" }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            marginBottom: "50px",
          }}
        >
          {/* Text Content */}
          <div style={{ flex: 1 }}>
            <div
              style={{
                background:
                  "linear-gradient(69.7deg, rgb(216, 81, 82) 40%, rgb(154, 27, 69) 100.1%)",
                color: "white",
                fontSize: "1.2rem",
                marginTop: "1rem",
                textAlign: "center",
                padding: "2rem",
                paddingTop: "5rem",
                paddingBottom: "5rem",
              }}
            >
              <div
                style={{
                  maxWidth: "1600px",
                  margin: "0 auto",
                }}
              >
                <div
                  style={{
                    fontSize: isMobile ? "3rem" : "4rem",
                    fontWeight: "bolder",
                  }}
                  className="homePageSubHeadings mt-2"
                >
                  Our Mission
                </div>
                <div
                  style={{ fontSize: "2rem", fontWeight: "bold" }}
                  className=" mb-4"
                >
                  Pioneering Pathways to Success
                </div>
                <div style={{ fontSize: "1.5rem" }}>
                  At IB Innovators, we empower future leaders with a holistic
                  learning approach. Beyond academics, we nurture essential life
                  skills—critical thinking, creativity, adaptability, and
                  empathy. Our students emerge resilient and confident, ready
                  for an ever-changing world.
                  <br />
                  <div style={{ margin: "13px" }}>
                    Join us in shaping tomorrow's changemakers!
                  </div>
                </div>
                <button
                  onClick={() => {
                    handleChange("/contactUs");
                  }}
                  style={{
                    marginTop: "2rem",
                    backgroundColor: "transparent",
                    borderRadius: "0.7rem",
                    border: "1px solid #fff",
                    padding: "0.5rem 1rem",
                    fontSize: "1.5rem",
                    color: "#fff", // Initial text color
                    transition: "background-color 0.3s, color 0.3s", // Smooth transition
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "white";
                    e.target.style.color = "rgb(154, 27, 69)";
                    e.target.style.border = "1px solid transparent";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = "transparent";
                    e.target.style.color = "#fff";
                    e.target.style.border = "1px solid #fff";
                  }}
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>

        <div style={{ paddingRight: "10vw", paddingLeft: "10vw" }}>
          <div
            style={{
              maxWidth: "1600px",
              margin: "0 auto",
            }}
          >
            {/* First Section: Image on Right */}
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              {/* Text Content */}
              <div style={{ flex: 1.2, paddingRight: "2rem" }}>
                <div
                  style={{
                    fontSize: isMobile ? "3rem" : "4rem",
                    textAlign: "center",
                    color: "#1F4E79",
                  }}
                  className="homePageSubHeadings mt-3"
                >
                  Why IBI?
                </div>
                {!isMobile ? (
                  <img
                    src={thirdImage}
                    alt="IBI Image"
                    style={{ float: "right", width: "50%" }}
                  />
                ) : (
                  <img
                    src={thirdImage}
                    alt="IBI Image"
                    style={{ width: "100%" }}
                  />
                )}
                <div
                  style={{ fontSize: "2rem" }}
                  className="homePageSubHeadings mt-3"
                >
                  1. Unmatched Expertise
                </div>
                <div style={{ fontSize: "1.8rem" }} className="p-3">
                  With a remarkable 8-year legacy in tutoring IB and IGCSE
                  students, IB Innovators possesses unparalleled insights into
                  the intricate demands of these prestigious academic curricula.
                  Our seasoned tutors offer invaluable perspectives that
                  profoundly impact our students' academic journeys.
                </div>

                <div
                  style={{ fontSize: "2rem" }}
                  className="homePageSubHeadings mt-3"
                >
                  2. Experienced Tutors
                </div>
                <div style={{ fontSize: "1.8rem" }} className="p-3">
                  IB Innovators prides itself on curating an exceptional team of
                  world-class tutors—accomplished educators, Ivy League
                  graduates, and subject matter experts. Beyond imparting
                  knowledge, our tutors instill a genuine passion for learning,
                  sparking intellectual curiosity in every student.
                </div>

                <div
                  style={{ fontSize: "2rem" }}
                  className="homePageSubHeadings mt-3"
                >
                  3. Personalized Support
                </div>
                <div style={{ fontSize: "1.8rem" }} className="p-3">
                  Acknowledging each student's uniqueness, we tailor our
                  tutoring approach to honor individual learning styles,
                  strengths, and areas for growth. Our tutors serve as mentors,
                  guiding students through academic challenges while nurturing
                  confidence and self-belief.
                </div>

                <div
                  style={{ fontSize: "2rem" }}
                  className="homePageSubHeadings mt-3"
                >
                  4. Flexible Learning, Your Way
                </div>
                <div style={{ fontSize: "1.8rem" }} className="p-3">
                  Convenience is paramount in effective learning. IB Innovators
                  offers you the freedom to schedule online sessions with your
                  tutor at your convenience, ensuring seamless and comfortable
                  learning experiences.
                </div>

                <div
                  style={{ fontSize: "2rem" }}
                  className="homePageSubHeadings mt-3"
                >
                  5. Comprehensive Academic Support
                </div>
                <div style={{ fontSize: "1.8rem" }} className="p-3">
                  At IB Innovators, no subject is beyond our expertise. From the
                  complexities of Mathematics and Sciences to the nuances of
                  Languages and Humanities, our tutors possess a depth of
                  knowledge that eradicates academic uncertainties.
                </div>

                <div
                  style={{ fontSize: "2rem" }}
                  className="homePageSubHeadings mt-3"
                >
                  6. Innovative Learning Solutions
                </div>
                <div style={{ fontSize: "1.8rem" }} className="p-3">
                  Central to our tutoring approach is our cutting-edge online
                  learning platform. Dynamic, interactive, and enriched with
                  real-time feedback, our platform provides an immersive
                  educational experience that transforms learning into a
                  delightful adventure.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
