import React, { useState, useEffect } from 'react';
import styles from './AcademicTimeline.module.css';

import One from '../../assets/timeH1.png';
import Two from '../../assets/timeH2.png';
import Three from '../../assets/timeH3.png';
import Four from '../../assets/timeH4.png';
import Five from '../../assets/timeH5.png';
import Six from '../../assets/timeH6.png';
import Seven from '../../assets/timeH7.png';
import Eight from '../../assets/timeH8.png';
import Nine from '../../assets/timeH9.png';

const imageSources = [One, Two, Three, Four, Five, Six, Seven, Eight, Nine];

const AcademicTimeline = ({ summerRef, rapidRef, winterRef, springRef }) => {
  const [activeImage, setActiveImage] = useState(null);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  useEffect(() => {
    let loadedImagesCount = 0;

    const handleImageLoad = () => {
      loadedImagesCount += 1;
      if (loadedImagesCount === imageSources.length) {
        setAllImagesLoaded(true);
      }
    };

    imageSources.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = handleImageLoad;
    });
  }, []);

  if (!allImagesLoaded) {
    return null; // or a loading spinner
  }

  return (
    <div className={styles.timelineContainer}>
      {imageSources.map((src, index) => (
        <img
          key={index}
          draggable={false}
          src={src}
          onClick={() => {
            if (index === 1) summerRef?.current.scrollIntoView({ behavior: 'smooth' });
            if (index === 3) rapidRef?.current.scrollIntoView({ behavior: 'smooth' });
            if (index === 5) winterRef?.current.scrollIntoView({ behavior: 'smooth' });
            if (index === 7) springRef?.current.scrollIntoView({ behavior: 'smooth' });
          }}
          style={{
            opacity: activeImage === index ? 1 : activeImage !== null ? 0.6 : 1,
            filter: activeImage === index ? 'none' : activeImage !== null ? 'blur(3px)' : 'none',
            cursor: [1, 3, 5, 7].includes(index) ? 'pointer' : 'default',
            userSelect: 'none'
          }}
          alt=""
          className={styles.timelineImage}
          onMouseOver={() => {
            if([1, 3, 5, 7].includes(index)){
              setActiveImage(index)
            } else {
              setActiveImage(null)
            }
          }}
          onMouseOut={() => setActiveImage(null)}
        />
      ))}
    </div>
  );
};

export default AcademicTimeline;
