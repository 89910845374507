import React from "react";
import check from '../../assets/check.png'
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";


export function ThankYouScreen({
    isMobile
  }){

    const navigate=useNavigate();
    return(
        <div style={{textAlign: 'center', color: "rgb(14, 56, 136)", paddingTop: '2rem'}}>
            <img src={check} alt="Tick Image" style={{maxHeight: '200px', marginBottom: '2rem'}} />
            <h1 style={{fontSize: isMobile ? "1.4rem" : "1.8rem"}}>
            <b>Thank You</b> for your interest at <b >IB <span style={{color: 'rgb(156, 16, 16)'}}>INNOVATORS</span></b>.
            </h1>
<br/>
            <h2 style={{fontSize: isMobile ? "1.1rem" : "1.5rem"}}>
Your signup form has been submitted. <br/>
Our Student Relations Coordinator will contact you shortly.
</h2>

<Button style={{ marginTop: '1rem', fontSize: '1.1rem'}} variant="contained" sx={{background: '#2344a4'}} onClick={() => {navigate("/")}}>Return To Home</Button>
        </div>
    )
}