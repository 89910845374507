import React, { useState } from "react";
import InvisibleCard from "../../Components/invisibleCard";
import Accordion from "../../Components/Accordion";
import TutorForm from "../../Components/TutorForm";
import groupPeople from "./../../assets/Revision.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MyContext } from "../../Context/MyContext";
import { Helmet } from "react-helmet";

export default function BecomeTutor() {

  const {seoData} = useContext(MyContext)

  
  const [thisPageSEO, setThisPageSEO] = useState({});

  useEffect(() => {
    if(seoData){
      setThisPageSEO(seoData?.find((e) => e?.pageName === "Become Tutor"))
    }
  }, [seoData])

  
  
  const createHelmetTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const headTags = Array.from(doc.head.children);
  
    return headTags.map((tag, index) => {
      const tagName = tag.tagName.toLowerCase();
  
      if (tagName === 'title') {
        return <title key={index}>{tag.innerText}</title>;
      } else if (tagName === 'meta') {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <meta key={index} {...attributes} />;
      } else if (tagName === 'link') {
        const attributes = Array.from(tag.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});
        return <link key={index} {...attributes} />;
      } else if (tagName === 'script' && tag.type === 'application/ld+json') {
        return <script key={index} type="application/ld+json">{tag.innerText}</script>;
      } else {
        return null;
      }
    });
  };

  const invisibleCardInfo = {
    cardsInfo: [
      {
        icons: <i className="fa-solid fa-crown cardIcon"></i>,
        title: "Bright Educator",
        para: "You need to be passionate about teaching and committed to providing high quality education for IB++tutors students. We want you to help our students and find innovative solutions to their questions..",
      },
      {
        icons: <i className="fa-solid fa-book cardIcon"></i>,
        title: "Experienced in the IBI",
        para: "To become an IB tutor at ++tutors you must have the required certifications and knowledge of the IB program. We would like to have someone onboard who is well qualified as well as experienced in teaching IB program students.",
      },
      {
        icons: <i className="fa-solid fa-user cardIcon"></i>,
        title: "Committed to Engagements",
        para: "At IB++tutors, we would value tutors who are dedicated to teaching and displaying commitment to building a global online IB tutoring to the max. We're looking for tutors to teach for a long or at least 6 months.",
      },
    ],
  };
  const accordionInfo = [
    {
      title: "Why Join Us?",
      content: (
        <ul>
          <li>
            Get IB tutoring opportunities across the globe, and meet students
            from diverse cultures and develop your cultural insights.
          </li>
          <li>Select your tutoring times to fit your ideal schedule.</li>
          <li>Work-life balance.</li>
          <li>Referral bonuses.</li>
          <li>
            Start with us as a freelancer for 1-10 hours per week, and grow the
            number of hours to move to be a part-time employee and then a
            full-time employee.
          </li>
          <li>
            We deploy state-of-the-art technology to ensure that students gain
            the best possible knowledge.
          </li>
        </ul>
      ),
    },
    {
      title: "Requirements as an IB tutor at IB Innovators",
      content: (
        <ul>
          <li>Passionate about teaching IB subjects.</li>
          <li>
            Ability to communicate information in a clear, pleasant, and
            effective manner.
          </li>
          <li>Enthusiastic and make learning more fun for students.</li>
          <li>Come up with innovative ideas to solve students’ queries.</li>
          <li>
            Devise new ways to explain concepts to students in an easy way.
          </li>
        </ul>
      ),
    },
    {
      title: "Required Qualifications",
      content: (
        <ul>
          <li>
            Extensive IB teacher or examiner experience at accredited IB
            schools.{" "}
          </li>
          <li>
            Experience in an online tutoring platform with an above 4.5+ rating.{" "}
          </li>
        </ul>
      ),
    },
  ];


  return (
    <React.Fragment>
      
<Helmet>
      {thisPageSEO?.schema && createHelmetTags(thisPageSEO.schema)}
    </Helmet>

      <div
        style={{
          background:
            "linear-gradient(69.7deg, rgb(216, 81, 82) 40%, rgb(154, 27, 69) 100.1%)",
            height: 'max-content',
            display: 'flex',
            alignItems: 'center', minHeight: '45rem', height: 'max-content'
        }}
      >
        <div style={{maxWidth: '1800px', margin: '0px auto'}}>
          <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
            <div style={{flex: 1}}>
              <div style={{ color: "white" }} className="p-3">
                <div className="homePageHeadings">
                  <div>
                    Join Our Team to elevate IB Online Tutoring Globally
                  </div>
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <button
                    style={{
                      padding: "0.5rem 1rem",
                      borderTopLeftRadius: "0px",
                    }}
                    className="mainButton"
                  >
                    Become a Tutor
                  </button>
                </div>
              </div>
            </div>
            <div  style={{flex: 1}}>
              <img
                src={groupPeople}
                style={{ objectFit: "cover", width: '100%', height: 'auto' }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{margin: '0px auto', maxWidth: '1800px', flex: 1}}>
      <div className="sideSectionMargin mt-5">
        <Accordion accordionInfo={accordionInfo} />
      </div>
      <div className="sideSectionMargin mt-5">
        <TutorForm />
      </div>
      </div>
    </React.Fragment>
  );
}
